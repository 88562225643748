

<!-- <app-navbar></app-navbar> -->

<main class="main-wrapper">
<router-outlet></router-outlet>
<ng-http-loader 
    [backdrop]="true"
    [backgroundColor]="'#f26524'"
    [debounceDelay]="100"
    [extraDuration]="300"
    [minDuration]="300"
    [opacity]=".6"
    [spinner]="spinnerStyle.skWave"></ng-http-loader>
</main>

<!-- <app-footer class="mt-auto"></app-footer>  -->
