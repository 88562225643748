import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ValuesService } from '../../services/values.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [NgbDropdownConfig]
})
export class HeaderComponent implements OnInit {

  public username: any;
  constructor(private _router: Router, private _val: ValuesService) { }

  ngOnInit(): void {
    this.username = this._val.USER_FIRSTNAME + this._val.USER_LASTNAME;
  }

  logoutFn():void{
    this._router.navigate(['/']);
  }
}
