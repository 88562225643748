<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
    <div class="page-head">
        <h3 *ngIf="isAddMode" class="page-title">
            <i class="icon-back mr-1"></i> {{ 'PACKAGE.ADD_PACKAGE' | translate }}
        </h3>
        <h3 *ngIf="!isAddMode" class="page-title">
            <i class="icon-back mr-1"></i> {{ 'PACKAGE.EDIT_PACKAGE' | translate }}
        </h3>
    </div>
    <form class="form-box form-block inner-block mt-2" (ngSubmit)="f4.form.valid && onAddPackage(f4)" #f4="ngForm"
        novalidate>
        <div class="card">
            <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                <div class="form-col mr-1">
                    <label class="form-label"> {{ 'PACKAGE.PACKAGE_NAME' | translate }}</label>
                    <div class="form-group">
                        <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditPackageData.packageName" id="packageName" #packageName="ngModel"
                            required name="packageName" placeholder="">
                        <div *ngIf="packageName.errors && (packageName.touched || f4.submitted)">
                            <div class="error_message" *ngIf="packageName.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter a package name</i>
                            </div>
                        </div>
                        <!-- <input [(ngModel)]="addEditPackageData.packageName" name="packageName" class="form-control form-modal" type="text" placeholder=""> -->
                    </div>
                </div>


            </div>
            <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                <div class="form-col mr-1">
                    <label class="form-label"> {{ 'PACKAGE.PACKAGE_PRICE' | translate }}</label>
                    <div class="form-group">
                        <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditPackageData.packagePrice" id="packagePrice" #packagePrice="ngModel"
                            required name="packagePrice" placeholder="">
                        <div *ngIf="packagePrice.errors && (packagePrice.touched || f4.submitted)">
                            <div class="error_message" *ngIf="packagePrice.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter the price</i>
                            </div>
                        </div>
                        <!-- <input [(ngModel)]="addEditPackageData.packagePrice" name="packagePrice" class="form-control form-modal" type="text" placeholder=""> -->
                    </div>
                </div>


            </div>


            <div class="form-row">
                <div class="form-col">
                    <label class="form-label"> {{ 'PACKAGE.DESCRIPTION' | translate }}</label>
                    <div class="form-group">
                        <textarea type="text" class="form-control form-width-half textarea"
                            [ngModelOptions]="{ updateOn: 'blur' }" [(ngModel)]="addEditPackageData.packageDesc"
                            name="packageDesc" id="packageDesc" #packageDesc="ngModel" required
                            placeholder=""></textarea>
                        <div *ngIf="packageDesc.errors && (packageDesc.touched || f4.submitted)">
                            <div class="error_message" *ngIf="packageDesc.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter package description</i>
                            </div>
                        </div>
                        <!-- <textarea  [(ngModel)]="addEditPackageData.packageDesc" name="packageDesc" class="form-control form-width-half textarea" type="text" placeholder=""></textarea> -->
                    </div>
                </div>
            </div>
            <div class="form-row d-flex flex-wrap form-action">
                <div class="form-col">
                    <a (click)="onCancelPackage()" class="btn btn-secondary btn-medium">{{ 'BUTTONS.BTN_CANCEL' | translate }}</a>
                    <button type="submit" *ngIf="isAddMode" [disabled]="!f4.form.valid"
                        class="btn btn-brand btn-medium">{{ 'BUTTONS.BTN_ADD_PACKAGE' | translate }}</button>
                    <button type="button" *ngIf="!isAddMode" [disabled]="!f4.form.valid"
                        (click)="f4.form.valid && onEditPackage(f4)" class="btn btn-brand btn-medium">{{
                        'BUTTONS.BTN_EDIT_PACKAGE' | translate }}</button>
                    <!-- <a routerLink="/addpackage" class="btn btn-brand btn-medium">Add Package</a> -->
                </div>
            </div>
        </div>
    </form>

</section>