<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
        <div class="page-head">
            <h3 *ngIf="isAddMode" class="page-title">
                <i class="icon-back mr-1"></i> {{ 'SERVICE_CATEGORY.ADD_SERVICE_CATEGORY' | translate }}
            </h3>
            <h3 *ngIf="!isAddMode" class="page-title">
                <i class="icon-back mr-1"></i> {{ 'SERVICE_CATEGORY.EDIT_SERVICE_CATEGORY' | translate }}
            </h3>
        </div>
        <form class="form-box form-block inner-block mt-2" (ngSubmit)="f5.form.valid && onAddServiceCategory(f5)" #f5="ngForm"
        novalidate>
            <div class="card">
                <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                    <div class="form-col mr-1">
                        <label class="form-label"> {{ 'SERVICE_CATEGORY.SERVICE_CATEGORY_NAME' | translate }}</label>
                        <div class="form-group">
                            <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditServiceCategoryData.serviceCatName" id="serviceCatName" #serviceCatName="ngModel"
                            required name="serviceCatName" placeholder="">
                            <div *ngIf="serviceCatName.errors && (serviceCatName.touched || f5.submitted)">
                                <div class="error_message" *ngIf="serviceCatName.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter a service category name</i>
                                </div>
                            </div>
                            <!-- <input [(ngModel)]="addEditServiceCategoryData.serviceCatName" name="serviceCatName" class="form-control form-modal" type="text" placeholder=""> -->
                        </div>
                    </div>

                 
                </div>
         
               
               
                <div class="form-row">
                    <div class="form-col">
                        <label class="form-label"> {{ 'SERVICE_CATEGORY.DESCRIPTION' | translate }}</label>
                        <div class="form-group">
                            <!-- <textarea [(ngModel)]="addEditServiceCategoryData.desc" name="desc" class="form-control form-width-half textarea" type="text" placeholder=""></textarea> -->
                            <textarea type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditServiceCategoryData.desc" id="desc" #desc="ngModel"
                            required name="desc" placeholder=""></textarea>
                            <div *ngIf="desc.errors && (desc.touched || f5.submitted)">
                                <div class="error_message" *ngIf="desc.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter the service category description</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            



            <div class="form-row d-flex flex-wrap form-action">
                <div class="form-col">
                    <a routerLink="/service-category" class="btn btn-secondary btn-medium">{{ 'BUTTONS.BTN_CANCEL' | translate }}</a>                    
                    <button type="submit" *ngIf="isAddMode" [disabled]="!f5.form.valid" class="btn btn-brand btn-medium">{{ 'BUTTONS.BTN_ADD_SERVICE_CATEGORY' | translate }}</button>
                    <button type="button" *ngIf="!isAddMode" [disabled]="!f5.form.valid" (click)="f5.form.valid && onEditServiceCategory(f5)" class="btn btn-brand btn-medium">{{'BUTTONS.BTN_EDIT_SERVICE_CATEGORY' | translate }}</button>
                    <!-- <a routerLink="/service-category" class="btn btn-brand btn-medium">{{ 'BUTTONS.BTN_ADD_SERVICE_CATEGORY' | translate }}</a> -->
                </div>
            </div>
        </div>
        </form>
    
</section>
