<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
   <section class="page-head">
      <h3 class="page-title">{{ 'COMMUNITY.HD_TITLE' | translate }}</h3>
     
   </section>
   <div class="d-flex flex-wrap justify-content-between mb-2">
      <div class="d-flex search-box form-position mr-2"><i class="icon-search vertical-middle"></i><input type="text" placeholder="Search by community name" class="form-control form-filter form-md form-modal round-md" name="keyword" value="" [(ngModel)]="term" /></div>
      <a class="btn btn-primary" routerLink="/addcommunity">{{ 'BUTTONS.BTN_ADD_COMMUNITY' | translate }}</a>
   </div>
<div class="card">
   <table class="table">
      <thead>
         <tr>
            <th>{{ 'COMMUNITY.COMMUNITY_NAME' | translate }}</th>
            <th>{{ 'COMMUNITY.DESCRIPTION' | translate }}</th>
            <th>{{ 'COMMUNITY.CREATED_DATE' | translate }}</th>
            <th colspan="2"></th>
         </tr>
      </thead>
      <tbody>
         <tr *ngFor="let community of communitiesList | filter:term |  orderBy: 'COMMUNITY_ID' : true | slice: (page-1) * pageSize : page * pageSize ">
            <td data-label="Community Name">{{community.COMMUNITY_NAME}}</td>
            <td data-label="Description">{{community.COMMUNITY_DESC}}</td>
            <td data-label="Created Date">{{community.CREATED_AT | date:'medium'}}</td>
            <td>
               <div ngbDropdown class="d-inline-block text-right">
               <span class="more-link" ngbDropdownToggle><span class="icon-ic_ellipsis icon-3x"></span></span>
               <div ngbDropdownMenu>
                   <button ngbDropdownItem (click)="onEditCommunity(community)"><i class="icon-edit icon-2x mr-1"></i>{{ 'BUTTONS.BTN_EDIT' | translate }}</button>
                   <button ngbDropdownItem  (click)="onClickDelete(community)"><i
                           class="icon-trash icon-2x mr-1"></i>{{ 'BUTTONS.BTN_DELETE' | translate }}</button>
               </div>
               </div>
           </td>
         </tr>
      </tbody>
   </table>
</div>
<div class="pagination-wrap d-flex flex-wrap justify-content-between" *ngIf="communitiesList.length !== 0">
   <p>{{'LABEL.LABEL_1' | translate }} {{page}} {{'LABEL.LABEL_2' | translate }} {{totNoOfPages}} </p>
   <ngb-pagination class="d-flex justify-content-end" [collectionSize]="communitiesList.length" [pageSize]="pageSize"
       [(page)]="page"></ngb-pagination>
</div>
</section>