<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
    <div class="page-head">
        <h3 *ngIf="isAddMode" class="page-title">
            <i class="icon-back mr-1"></i> {{ 'INCLUSIONS.ADD_INCLUSION' | translate }}
        </h3>
        <h3 *ngIf="!isAddMode" class="page-title">
            <i class="icon-back mr-1"></i> {{ 'INCLUSIONS.EDIT_INCLUSION' | translate }}
        </h3>
    </div>
    <form class="form-box form-block inner-block mt-2" (ngSubmit)="f1.form.valid && onAddInclusion(f1)" #f1="ngForm"
        novalidate>
        <div class="card">
            <div class="form-row">
                <div class="form-col mr-1">
                    <label class="form-label"> {{ 'INCLUSIONS.INCLUSION_NAME' | translate }}</label>
                    <div class="form-group">
                        <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditInclusionData.inclusionName" id="inclusionName" #inclusionName="ngModel"
                            required name="inclusionName" placeholder="">
                        <div *ngIf="inclusionName.errors && (inclusionName.touched || f1.submitted)">
                            <div class="error_message" *ngIf="inclusionName.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter an Inclusion name</i>
                            </div>
                        </div>
                        <!-- <input [(ngModel)]="addEditInclusionData.inclusionName" name="inclusionName" class="form-control form-modal" type="text" placeholder=""> -->
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col mr-1">
                    <label class="form-label"> {{ 'INCLUSIONS.PRICE' | translate }}</label>
                    <div class="form-group">
                        <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditInclusionData.inclusionPrice" id="inclusionPrice"
                            #inclusionPrice="ngModel" required name="inclusionPrice" placeholder="">
                        <div *ngIf="inclusionPrice.errors && (inclusionPrice.touched || f1.submitted)">
                            <div class="error_message" *ngIf="inclusionPrice.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter the price</i>
                            </div>
                        </div>
                        <!-- <input [(ngModel)]="addEditInclusionData.inclusionPrice" name="inclusionPrice" class="form-control form-modal" type="text" placeholder=""> -->
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-col">
                    <label class="form-label"> {{ 'INCLUSIONS.DESCRIPTION' | translate }}</label>
                    <div class="form-group">
                        <textarea type="text" class="form-control form-width-half textarea"
                            [ngModelOptions]="{ updateOn: 'blur' }" [(ngModel)]="addEditInclusionData.inclusionDesc"
                            name="inclusionDesc" id="inclusionDesc" #inclusionDesc="ngModel" required
                            placeholder=""></textarea>
                        <div *ngIf="inclusionDesc.errors && (inclusionDesc.touched || f1.submitted)">
                            <div class="error_message" *ngIf="inclusionDesc.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter inclusion description</i>
                            </div>
                        </div>
                        <!-- <textarea  [(ngModel)]="addEditInclusionData.inclusionDesc" name="inclusionDesc" class="form-control form-width-half textarea" type="text" placeholder=""></textarea> -->
                    </div>
                </div>
            </div>




            <div class="form-row d-flex flex-wrap form-action">
                <div class="form-col">
                    <a (click)="onCancelInclusion()" class="btn btn-secondary btn-medium">{{ 'BUTTONS.BTN_CANCEL' |
                        translate }}</a>
                    <button type="submit" *ngIf="isAddMode" [disabled]="!f1.form.valid"
                        class="btn btn-brand btn-medium">{{ 'BUTTONS.BTN_ADD_INCLUSION' | translate }}</button>
                    <button type="button" *ngIf="!isAddMode" [disabled]="!f1.form.valid"
                        (click)="f1.form.valid && onEditInclusion(f1)" class="btn btn-brand btn-medium">{{'BUTTONS.BTN_EDIT_INCLUSION' | translate }}</button>
                    <!-- <a routerLink="/orders" class="btn btn-brand btn-medium">Add Inclusion</a> -->
                </div>
            </div>
        </div>
    </form>

</section>