<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">

   <div class="page-head">
      <h3 *ngIf="isAddMode" class="page-title">
         <a class="" routerLink="/vathiyars"><i class="icon-back mr-1"></i> </a> {{ 'VATHIYARS.ADD_VATHIYAR' | translate
         }}

      </h3>
      <h3 *ngIf="!isAddMode" class="page-title">
         <a class="" routerLink="/vathiyars"> <i class="icon-back mr-1"></i></a> {{ 'VATHIYARS.EDIT_VATHIYAR' |
         translate }}
      </h3>
   </div>
   <form class="form-box form-block inner-block mt-2" (ngSubmit)="f9.form.valid && checkUserExistence(f9)" #f9="ngForm"
      novalidate enctype="multipart/form-data">

      <!-- Basic Info -->
      <section id="basic" class="card group">

         <h2 class="mb-2 visible-large hide-small ui-step-head">{{ 'VATHIYARS.BASIC_INFO' | translate }} </h2>
         <div class="form-row row d-flex flex-wrap align-items-center justify-space-between">
            <div class="form-col two-col">
               <label class="form-label">{{ 'VATHIYARS.FIRST_NAME' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input class="form-control" [(ngModel)]="addVathiyarData.fname" name="fname" type="text"
                     placeholder="" appAlphabetValidator/> -->
                  <input type="text" class="form-control" [ngModelOptions]="{ updateOn: 'blur' }"
                     [(ngModel)]="addVathiyarData.fname" id="fname" #fname="ngModel" required name="fname"
                     placeholder="">
                  <div *ngIf="fname.errors && (fname.touched || f9.submitted)">
                     <div class="error_message" *ngIf="fname.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Please enter the first name</i>
                     </div>
                  </div>

               </div>
            </div>
            <div class="form-col two-col">
               <label class="form-label">{{ 'VATHIYARS.LAST_NAME' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input class="form-control" [(ngModel)]="addVathiyarData.lname" name="lname" type="text"
                     placeholder="" appAlphabetValidator/>
                   -->
                  <input type="text" class="form-control" [ngModelOptions]="{ updateOn: 'blur' }"
                     [(ngModel)]="addVathiyarData.lname" id="lname" #lname="ngModel" required name="lname"
                     placeholder="">
                  <div *ngIf="lname.errors && (lname.touched || f9.submitted)">
                     <div class="error_message" *ngIf="lname.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Please enter the last name</i>
                     </div>
                  </div>
               </div>
            </div>


         </div>

         <div class="form-row row d-flex flex-wrap align-items-center justify-space-between">
            <div class="form-col two-col">
               <label class="form-label"> {{'VATHIYARS.EMAIL_ID' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input [(ngModel)]="addVathiyarData.email" name="email" class="form-control" type="email"
                     placeholder="" appEmailValidator /> -->

                  <input type="email" class="form-control full-width" [ngModelOptions]="{ updateOn: 'blur' }"
                     [(ngModel)]="addVathiyarData.email" name="email" id="email" #email="ngModel" required
                     placeholder="" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                  <div *ngIf="email.errors && (email.touched || f9.submitted)">
                     <div class="error_message" *ngIf="email.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Email is a required field! </i>
                     </div>
                     <div class="error_message" *ngIf="email.errors?.pattern">
                        <span class="e_arrow"></span>
                        <i>This is not a valid Email!!!</i>
                     </div>
                  </div>
               </div>
            </div>
            <div class="form-col two-col">
               <label class="form-label"> {{ 'VATHIYARS.AADHAR_CARD_NUMBER' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input [(ngModel)]="addVathiyarData.aadharNumber" name="aadharNumber" class="form-control" type="text"
                     placeholder="" [minLengthValue]="12" [maxLengthValue]="12" appMinMaxLengthValidator appNumericValidator/> -->


                  <input type="text" [(ngModel)]="addVathiyarData.aadharNumber" name="aadharNumber" class="form-control"
                     placeholder="" id="aadharNumber" #aadharNumber="ngModel" required
                     pattern="^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$" />
                  <div *ngIf="aadharNumber.errors && (aadharNumber.touched || f9.submitted)">
                     <div class="error_message" *ngIf="aadharNumber.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Aadhar number is a required field! </i>
                     </div>
                     <div class="error_message" *ngIf="aadharNumber.errors?.pattern">
                        <span class="e_arrow"></span>
                        <i>This is not a valid Aadhar number!</i>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="form-row row">

            <div class="form-col two-col">
               <label class="form-label">{{ 'VATHIYARS.PROFILE_PICTURE' | translate }} <sup
                     class="text-danger mandatory-key">*</sup><span class="help-text justify-content-end">(JPG and PNG
                     with max size 5 MB)</span></label>
               <input type="file" class="m-1 p-1" (change)="onSelectFile($event,'profileImg')" accept="image/*">
               <!-- <div *ngIf="!selectedProfileImgFile">{{profileImgPresence}}</div> -->
               <div *ngIf="profileImageUrl"><img [src]="profileImageUrl" height="100" width="100"></div>

            </div>
            <div class="form-col two-col">
               <label class="form-label">{{ 'VATHIYARS.AADHAR_IMAGE' | translate }} <sup
                     class="text-danger mandatory-key">*</sup><span class="help-text justify-content-end">(JPG and PNG
                     with max size 5 MB)</span></label>
               <input type="file" class="m-1 p-1" (change)="onSelectFile($event, 'aadharImg')" accept="image/*">
               <div *ngIf="!selectedAadharImgFile">{{aadharImgPresence}}</div>
               <div *ngIf="aadharImageUrl"><img [src]="aadharImageUrl" height="100" width="100"></div>

            </div>
         </div>
         <div class="form-row">
            <div class="form-col">
               <label class="form-label"> {{ 'VATHIYARS.ADDRESS' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <textarea class="form-control" [(ngModel)]="addVathiyarData.address" name="address"></textarea> -->
                  <textarea type="text" class="form-control" [ngModelOptions]="{ updateOn: 'blur' }"
                     [(ngModel)]="addVathiyarData.address" name="address" id="address" #address="ngModel" required
                     placeholder=""></textarea>
                  <div *ngIf="address.errors && (address.touched || f9.submitted)">
                     <div class="error_message" *ngIf="address.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Please enter the address</i>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="form-row d-flex flex-wrap align-items-center justify-space-between">


            <div class="form-col col-md-6">
               <label class="form-label">{{ 'VATHIYARS.COUNTRY' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <select [(ngModel)]="addVathiyarData.country" name="country" class="form-control minimal">
                     <option value="India">{{ 'VATHIYARS.INDIA' | translate }}</option>
                  </select> -->
                  <select [(ngModel)]="addVathiyarData.country" [ngModelOptions]="{ updateOn: 'blur' }" name="country"
                     id="country" #country="ngModel" required class="form-control minimal">
                     <option value="India">{{ 'VATHIYARS.INDIA' | translate }}</option>
                  </select>
                  <div *ngIf="country.errors && (country.touched || f9.submitted)">
                     <div class="error_message" *ngIf="country.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Please select the country </i>
                     </div>
                  </div>
               </div>
            </div>
            <div class="form-col col-md-6">
               <label class="form-label"> {{ 'VATHIYARS.STATE' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <select [(ngModel)]="addVathiyarData.state" name="state"
                     (change)="onStateChange(addVathiyarData.state,$event)"
                     class="form-control form-base form-modal minimal"
                     [disabled]="(!addVathiyarData.country)">
                     <option [ngValue]="state.STATE_ID" *ngFor="let state of statesList">{{state.STATE_NAME}}
                     </option>
                  </select> -->

                  <select [(ngModel)]="addVathiyarData.state" [ngModelOptions]="{ updateOn: 'blur' }" name="state"
                     (ngModelChange)="onStateChange(state,$event)" [disabled]="(!addVathiyarData.country)"
                     id="state" #state="ngModel" required class="form-control minimal">
                     <option [ngValue]="st.STATE_ID" *ngFor="let st of statesList">{{st.STATE_NAME}}
                     </option>
                  </select>
                  <div *ngIf="state.errors && (state.touched || f9.submitted)">
                     <div class="error_message" *ngIf="state.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Please select the state </i>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-row d-flex flex-wrap align-items-center justify-space-between">

            <div class="form-col col-md-6">
               <label class="form-label"> {{ 'VATHIYARS.CITY' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <select  [(ngModel)]="addVathiyarData.district" 
                           name="district"
                           (change)="onDistrictChange(addVathiyarData.district,$event)"
                           class="form-control form-base form-modal minimal"
                           [disabled]="(!addVathiyarData.state)">
                     <option [ngValue]="district.DISTRICT_ID" *ngFor="let district of districtsList">
                        {{district.DISTRICT_NAME}}
                     </option>
                  </select> -->

                  <select [(ngModel)]="addVathiyarData.district" [ngModelOptions]="{ updateOn: 'blur' }" 
                     name="district"
                     (change)="onDistrictChange(addVathiyarData.district, $event)" 
                     [disabled]="(!addVathiyarData.state)"
                     id="district" #district="ngModel" required class="form-control minimal">
                     <option [ngValue]="district.DISTRICT_ID" *ngFor="let district of districtsList">
                        {{district.DISTRICT_NAME}}
                     </option>
                  </select>
                  <div *ngIf="district.errors && (district.touched || f9.submitted)">
                     <div class="error_message" *ngIf="district.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Please select the district </i>
                     </div>
                  </div>
               </div>
            </div>
            <div class="form-col col-md-6">
               <label class="form-label"> {{'VATHIYARS.PINCODE' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input class="form-control" [(ngModel)]="addVathiyarData.pincode" name="pincode" type="text"
                     placeholder="" appNumericValidator  [minLengthValue]="6" appMinMaxLengthValidator/> -->

                  <input type="text" [(ngModel)]="addVathiyarData.pincode" name="pincode" class="form-control"
                     maxLength="6" minLength="6" placeholder="" id="pincode" #pincode="ngModel" required
                     pattern="^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$" />
                  <div *ngIf="pincode.errors && (pincode.touched || f9.submitted)">
                     <div class="error_message" *ngIf="pincode.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Zipcode is a required field! </i>
                     </div>
                     <div class="error_message" *ngIf="pincode.errors?.pattern">
                        <span class="e_arrow"></span>
                        <i>This is not a valid Zipcode!</i>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-row row">
            <div class="form-col two-col">
               <label class="form-label">{{ 'VATHIYARS.PHONE_NUMBER' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
                     <div class="input-group flex-nowrap">
                        <span class="input-group-text number-prepend" id="addon-wrapping">+91</span>
                       
                        <input type="text" [(ngModel)]="addVathiyarData.phoneNo" name="phoneNo" 
                            class="form-control" 
                            maxLength="10" placeholder="10 digit mobile number" 
                            id="phoneNo" #phoneNo="ngModel" required 
                            pattern="^[0-9]{10}$"
                            aria-label="Username" aria-describedby="addon-wrapping"
                            /> 
                      </div>
                      <div *ngIf="phoneNo.errors && (phoneNo.touched || f9.submitted)">
                        <div class="error_message" *ngIf="phoneNo.errors?.required">
                           <span class="e_arrow"></span>
                           <i>Phone number is a required field! </i>
                        </div>
                        <div class="error_message" *ngIf="phoneNo.errors?.pattern">
                           <span class="e_arrow"></span>
                           <i>This is not a valid number!</i>
                        </div>
                     </div>
              
            </div>

         </div>

      </section>
      <!-- End Basic Info -->

      <!-- Services -->
      <section id="services" class="card group">

         <h2 class="mb-2 visible-large hide-small ui-step-head">{{ 'VATHIYARS.SERVICES' | translate }}</h2>

         <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
            <div #myDiv class="form-col col-md-8">
               <label class="form-label">{{ 'VATHIYARS.SERVICES' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <ng-select name="services" [items]="serviceDropDownList" id="services" [searchable]="true"
                     [multiple]="true" [(ngModel)]="serviceSelectedItems" bindLabel="SERVICE_NAME" required>
                     <ng-template ng-header-tmp>
                        <div>
                           <button class="btn btn-link"
                              (click)="onSelectAllClick(serviceDropDownList, serviceSelectedItems )">Select All</button>
                        </div>
                     </ng-template>
                  </ng-select>                 
               </div>
            </div>
            <div class="form-col col-md-4">
               <label class="form-label"> {{ 'VATHIYARS.LANGUAGES' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <ng-select name="languages" [items]="langDropDownList" [searchable]="true" [multiple]="true"
                     [(ngModel)]="langSelectedItems" bindLabel="LANGUAGE_NAME" required>
                  </ng-select>
               </div>
            </div>
         </div>
         <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
            <div class="form-col col-md-6">
               <label class="form-label"> {{ 'VATHIYARS.LOCATION_PREFERENCES' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <ng-select name="locations" [items]="locationDropDownList" [searchable]="true" [multiple]="true"
                     [(ngModel)]="locationSelectedItems" bindLabel="LOCATION_NAME" required>
                  </ng-select>
               </div>
            </div>
            <div class="form-col col-md-6">
               <label class="form-label"> {{ 'VATHIYARS.COMMUNITY' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <ng-select name="community" [items]="communityDropDownList" [searchable]="true" [multiple]="true"
                     [(ngModel)]="communitySelectedItems" bindLabel="COMMUNITY_NAME" required>
                  </ng-select>
               </div>
            </div>
         </div>
      </section>
      <!-- End Documents -->

      <!-- Account Details -->
      <section id="accounts" class="card group last">

         <h2 class="mb-2 visible-large hide-small ui-step-head">{{ 'VATHIYARS.ACCOUNT_INFO' | translate }}</h2>
         <div class="form-row row d-flex flex-wrap align-items-center justify-space-between">
            <div class="form-col two-col">
               <label class="form-label">{{'VATHIYARS.BANK_NAME' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input class="form-control" [(ngModel)]="addVathiyarData.bankName" name="bankName" type="text"
                     placeholder="" [minLengthValue]="3" appMinMaxLengthValidator appAlphabetValidator /> -->
              
                  
                  <input type="text" [(ngModel)]="addVathiyarData.bankName" name="bankName" class="form-control"
                     minlength="3" placeholder="" id="bankName" #bankName="ngModel" required
                     pattern="^[a-zA-Z ]*$" />
                  <div *ngIf="bankName.errors && (bankName.touched || f9.submitted)">
                     <div class="error_message" *ngIf="bankName.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Bank name is a required field! </i>
                     </div>
                     <div class="error_message" *ngIf="bankName.errors?.pattern">
                        <span class="e_arrow"></span>
                        <i>This is not a valid Bank Name!</i>
                     </div>
                  </div>
               </div>
            </div>
            <div class="form-col two-col">
               <label class="form-label">{{'VATHIYARS.ACCOUNT_NUMBER' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input class="form-control" [(ngModel)]="addVathiyarData.bankAccNo" name="bankAccNo" type="text"
                     placeholder="" [minLengthValue]="10" appMinMaxLengthValidator appNumericValidator /> -->

                     
                  <input type="text" [(ngModel)]="addVathiyarData.bankAccNo" name="bankAccNo" class="form-control"
                     minlength="9" placeholder="" id="bankAccNo" #bankAccNo="ngModel" required
                     pattern="^[0-9]{9,18}$" />
                  <div *ngIf="bankAccNo.errors && (bankAccNo.touched || f9.submitted)">
                     <div class="error_message" *ngIf="bankAccNo.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Bank Account Number is a required field! </i>
                     </div>
                     <div class="error_message" *ngIf="bankAccNo.errors?.pattern">
                        <span class="e_arrow"></span>
                        <i>This is not a valid Bank Account Number!</i>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-row row d-flex flex-wrap align-items-center justify-space-between">
            <div class="form-col two-col">
               <label class="form-label">{{'VATHIYARS.IFSC_CODE' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input class="form-control" [(ngModel)]="addVathiyarData.bankIFSCCode" name="bankIFSCCode" type="text"
                     placeholder="" appAlphaNumericValidator /> -->

                  <input type="text" [(ngModel)]="addVathiyarData.bankIFSCCode" name="bankIFSCCode" class="form-control"
                     minlength="9" placeholder="" id="bankIFSCCode" #bankIFSCCode="ngModel" required
                     pattern="^[A-Z]{4}0[A-Z0-9]{6}$" />
                  <div *ngIf="bankIFSCCode.errors && (bankIFSCCode.touched || f9.submitted)">
                     <div class="error_message" *ngIf="bankIFSCCode.errors?.required">
                        <span class="e_arrow"></span>
                        <i>IFSC code is a required field! </i>
                     </div>
                     <div class="error_message" *ngIf="bankIFSCCode.errors?.pattern">
                        <span class="e_arrow"></span>
                        <i>This is not a valid IFSC code!</i>
                     </div>
                  </div>
               </div>
            </div>
            <div class="form-col two-col">
               <label class="form-label">{{'VATHIYARS.UPI_ID' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input class="form-control" [(ngModel)]="addVathiyarData.UPIID" name="UPIID" type="text"
                     placeholder="" /> -->
                     
                  
                  <input type="text" [(ngModel)]="addVathiyarData.UPIID" name="UPIID" class="form-control"
                     placeholder="" id="UPIID" #UPIID="ngModel" required
                     />
                  <div *ngIf="UPIID.errors && (UPIID.touched || f9.submitted)">
                     <div class="error_message" *ngIf="UPIID.errors?.required">
                        <span class="e_arrow"></span>
                        <i>UPI ID is a required field! </i>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-row row d-flex flex-wrap align-items-center justify-space-between">
            <div class="form-col two-col">
               <label class="form-label">{{'VATHIYARS.ACCOUNT_TYPE' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <select [(ngModel)]="addVathiyarData.bankAccType" name="bankAccType"
                     class="form-control form-base form-modal minimal">
                     <option [ngValue]="accType.ACCT_TYPE_ID" *ngFor="let accType of accountTypesList">
                        {{accType.ACCT_TYPE_NAME}}
                     </option>
                  </select> -->

                  <select [(ngModel)]="addVathiyarData.bankAccType" [ngModelOptions]="{ updateOn: 'blur' }"
                     name="bankAccType" id="bankAccType" #bankAccType="ngModel" required 
                     class="form-control minimal">
                     <option [ngValue]="accType.ACCT_TYPE_ID" *ngFor="let accType of accountTypesList">
                        {{accType.ACCT_TYPE_NAME}}
                     </option>
                  </select>
                  <div *ngIf="bankAccType.errors && (bankAccType.touched || f9.submitted)">
                     <div class="error_message" *ngIf="bankAccType.errors?.required">
                        <span class="e_arrow"></span>
                        <i>Please select the account type </i>
                     </div>
                  </div>
               </div>
            </div>
            <div class="form-col two-col">
               <label class="form-label">{{'VATHIYARS.UPI_NAME' | translate }} <sup
                     class="text-danger mandatory-key">*</sup> </label>
               <div class="form-group">
                  <!-- <input class="form-control" [(ngModel)]="addVathiyarData.UPIName" name="UPIName" type="text"
                     placeholder="" appAlphaNumericValidator /> -->

                  <input type="text" [(ngModel)]="addVathiyarData.UPIName" name="UPIName" class="form-control"
                     placeholder="" id="UPIName" #UPIName="ngModel" required
                     pattern="^[A-Za-z0-9]*$" />
                  <div *ngIf="UPIName.errors && (UPIName.touched || f9.submitted)">
                     <div class="error_message" *ngIf="UPIName.errors?.required">
                        <span class="e_arrow"></span>
                        <i>UPI Name is a required field! </i>
                     </div>
                     <div class="error_message" *ngIf="UPIName.errors?.pattern">
                        <span class="e_arrow"></span>
                        <i>This is not a valid UPI Name!</i>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <div class="form-row d-flex flex-wrap form-action">
         <div class="form-col">
            <a (click)="onCancelVathiyar()" class="btn btn-secondary btn-medium">{{'BUTTONS.BTN_CANCEL' | translate }}</a>
            <button type="submit" *ngIf="isAddMode"  [disabled]="!f9.form.valid || !aadharImageUrl || !profileImageUrl" 
            id="addVaathiar"  class="btn btn-brand btn-medium">{{'BUTTONS.BTN_ADD_VATHIYAR' | translate }}</button>
              <button type="button" *ngIf="!isAddMode" [disabled]="!f9.form.valid || !aadharImageUrl || !profileImageUrl"
              (click)="f9.form.valid && onEditImage(f9, profileImgId, aadharImgId)"
               class="btn btn-brand btn-medium">{{'BUTTONS.BTN_EDIT_VATHIYAR' | translate }}</button>


            <!-- <button *ngIf="isAddMode" (click)="checkUserExistence()"
               [disabled]="(!addVathiyarData.fname || !addVathiyarData.lname || 
                           !addVathiyarData.email || !addVathiyarData.aadharNumber || 
                           !addVathiyarData.address || !addVathiyarData.country || 
                           !addVathiyarData.state || !addVathiyarData.district || 
                           !addVathiyarData.pincode || !addVathiyarData.phoneNo || !addVathiyarData.bankName ||
                           !addVathiyarData.bankAccNo || !addVathiyarData.bankIFSCCode ||
                           !addVathiyarData.UPIID || !addVathiyarData.bankAccType || !addVathiyarData.UPIName || !selectedProfileImgFile || !selectedAadharImgFile)"
               class="btn btn-brand btn-medium">
               {{'BUTTONS.BTN_ADD_VATHIYAR' | translate }}
            </button> -->
            <!-- <button *ngIf="!isAddMode" (click)="onEditImage(profileImgId, aadharImgId)"
               class="btn btn-brand btn-medium">{{'BUTTONS.BTN_EDIT_VATHIYAR' | translate }}</button> -->
         </div>
      </div>
   </form>

</section>