import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpGlobalService } from 'src/app/services/httpglobal.service';
import { ValuesService } from 'src/app/services/values.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-addvathiyar',
  templateUrl: './addvathiyar.component.html',
})
export class AddvathiyarComponent implements OnInit {

  public addVathiyarData: any = {
    "fname": "",
    "lname": "",
    "email": "",
    "aadharNumber": "",
    "address": "",
    "country": "",
    "state": "",
    "district": "",
    "pincode": "",
    "phoneNo": "",
    "bankName": "",
    "bankAccNo": "",
    "bankIFSCCode": "",
    "UPIID": "",
    "bankAccType": "",
    "UPIName": ""
  };
  public isAddMode: boolean;
  public profileImageUrl: any = "";
  public profileImgId: any = "";
  public aadharImgId: any = "";
  public aadharImageUrl: any = "";

  public selectedProfileImgFile: File = null;
  public selectedAadharImgFile: File = null;
  public statesList: any;
  public districtsList: any;
  public communitiesList: any;
  public accountTypesList: any;

  // Services multiselect drop down variables
  public serviceDropDownList: any = [];
  public serviceSelectedItems: any = [];

  // Language multiselect drop down variables
  public langDropDownList: any = [];
  public langSelectedItems: any = [];

  // Location multiselect drop down variables
  public locationDropDownList: any = [];
  public locationSelectedItems: any = [];

  // Community multiselect drop down variables
  public communityDropDownList: any = [];
  public communitySelectedItems: any = [];

  public profileImgPresence: any; 
  public aadharImgPresence: any;

  constructor(private _gs: HttpGlobalService,
    private _router: Router,
    private _val: ValuesService,
    private _ws: WorkspaceService,
    public formBuilder: FormBuilder) {
    this.isAddMode = this._val.isAddMode;
  }
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('f9') vathiyarForm: NgForm;
  ngOnInit(): void {
    this.serviceDropDownList = this._val.SERVICES;
    if (this._val.EDIT_VATHIYAR_DATA) {
      this.addVathiyarData.fname = this._val.EDIT_VATHIYAR_DATA.FIRST_NAME;
      this.addVathiyarData.lname = this._val.EDIT_VATHIYAR_DATA.LAST_NAME;
      this.addVathiyarData.email = this._val.EDIT_VATHIYAR_DATA.EMAIL_ID;
      this.addVathiyarData.aadharNumber = this._val.EDIT_VATHIYAR_DATA.AADHAAR_NUMBER;
      this.addVathiyarData.address = this._val.EDIT_VATHIYAR_DATA.LOCATION_ADDRESS;
      this.addVathiyarData.country = this._val.EDIT_VATHIYAR_DATA.LOCATION_ADDRESS_COUNTRY;
      this.addVathiyarData.state = Number.parseInt(this._val.EDIT_VATHIYAR_DATA.LOCATION_ADDRESS_STATE);
      this.addVathiyarData.district = Number.parseInt(this._val.EDIT_VATHIYAR_DATA.LOCATION_ADDRESS_CITY);
      this.addVathiyarData.pincode = this._val.EDIT_VATHIYAR_DATA.LOCATION_ADDRESS_PINCODE;
      this.addVathiyarData.phoneNo = this._val.EDIT_VATHIYAR_DATA.CONTACT_NO;
      this.addVathiyarData.bankName = this._val.EDIT_VATHIYAR_DATA.NAME_OF_BANK;
      this.addVathiyarData.bankAccNo = this._val.EDIT_VATHIYAR_DATA.BANK_ACC_NO;
      this.addVathiyarData.bankIFSCCode = this._val.EDIT_VATHIYAR_DATA.BANK_IFSC_CODE;
      this.addVathiyarData.UPIID = this._val.EDIT_VATHIYAR_DATA.UPI_ID;
      this.addVathiyarData.UPIName = this._val.EDIT_VATHIYAR_DATA.UPI_NAME;
      this.addVathiyarData.bankAccType = this._val.EDIT_VATHIYAR_DATA.BANK_ACC_TYPE_ID;

      this.onStateChange(this.addVathiyarData.state)
      this.serviceSelectedItems = this._val.EDIT_VATHIYAR_DATA.SERVICES;
      for (let j = 0; j < this._val.EDIT_VATHIYAR_DATA.LANGUAGE_ID.length; j++) {
        this.langSelectedItems.push(this._val.EDIT_VATHIYAR_DATA.LANGUAGE_ID[j]);
      }
      this.locationSelectedItems = this._val.EDIT_VATHIYAR_DATA.LOCATION_PREFERENCE;
      this.communitySelectedItems = this._val.EDIT_VATHIYAR_DATA.COMMUNITY_ID;
      this.profileImgId = this._val.EDIT_VATHIYAR_DATA.PROFILE_IMAGE ? this._val.EDIT_VATHIYAR_DATA.PROFILE_IMAGE._id : '';
      this.profileImageUrl = environment.imageServerUrl + environment.url.viewImgFile + this._val.EDIT_VATHIYAR_DATA.PROFILE_IMAGE.filename;
      this.aadharImgId = this._val.EDIT_VATHIYAR_DATA.AADHAAR_CARD_IMAGE ? this._val.EDIT_VATHIYAR_DATA.AADHAAR_CARD_IMAGE._id : '';
      this.aadharImageUrl = environment.imageServerUrl + environment.url.viewImgFile + this._val.EDIT_VATHIYAR_DATA.AADHAAR_CARD_IMAGE.filename;
    }
  }

  ngAfterViewInit() {
    // console.log(this.serviceSelectedItems);
    // console.log(this.locationSelectedItems);
    // console.log(this.langSelectedItems);
    setTimeout(() => {
      if (this._val.SERVICES.length > 0) {
        this.serviceDropDownList = this._val.SERVICES;
      }

      if (this._val.LANGUAGES.length > 0) {
        this.langDropDownList = this._val.LANGUAGES;
      }

      if (this._val.COMMUNITIES.length > 0) {
        this.communityDropDownList = this._val.COMMUNITIES;
      }

      if (this._val.STATES && this._val.STATES.length > 0) {
        this.statesList = this._val.STATES;
      }

      if (this._val.ACCOUNT_TYPES && this._val.ACCOUNT_TYPES.length > 0) {
        this.accountTypesList = this._val.ACCOUNT_TYPES;
      }

    }, 2000);

  }
  onSelectAllClick(items, selectedItem) {
    selectedItem = items.map(item => item.id);
    // this.serviceSelectedItems = selectedItem;
    this.serviceSelectedItems = [...items];
    // this.blur()
    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
    // document.getElementById('id').blur()
  }

  onItemSelect(item: any, whichSelect: any) {
    // console.log(this.serviceSelectedItems);
    // console.log(this.locationSelectedItems);
    // console.log(this.langSelectedItems);

  }


  onStateChange(stateId: any, event?: any) {
    console.log(stateId);
    console.log(event);
    this._ws.getAllDistricts(32);
    setTimeout(() => {
      this.districtsList = this._val.DISTRICTS
    }, 200);

  }

  onDistrictChange(districtId: any, event?: any) {
    this._ws.getAllLocations(districtId);
    setTimeout(() => {
      if (this._val.LOCATIONS.length > 0) {
        this.locationDropDownList = this._val.LOCATIONS;
      }
    }, 200);
  }

  onSelectFile(event, whichImg) {
    // console.log(event.target.files);
    if (event.target.files) {
      var reader = new FileReader();
      if (whichImg === 'profileImg') {
        this.selectedProfileImgFile = event.target.files[0];
        console.log(this.selectedProfileImgFile);
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          console.log(event.target);
          this.profileImageUrl = event.target.result;
        }
      }
      if (whichImg === 'aadharImg') {
        this.selectedAadharImgFile = event.target.files[0];
        console.log(this.selectedAadharImgFile);
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          console.log(event.target);
          this.aadharImageUrl = event.target.result;
        }
      }
    }
  }


  onEditImage(form: NgForm, oldProfileId, oldAadharId) {
    console.log('edit image--------');
    if (this.selectedProfileImgFile) {
      console.log('profile');
      let url = environment.imageServerUrl + environment.url.deleteImg + oldProfileId;
      this._gs.getCall(url)
        .subscribe(
          (res) => {
            console.log(res);
            if (res['success'] == true) {
              this.onAddImage();
            }
          },
          err => {
            console.log(err);
          }
        )

    } else if (this.selectedAadharImgFile) {
      console.log('aadhar ');
      if (this.selectedAadharImgFile) {
        let url = environment.imageServerUrl + environment.url.deleteImg + oldAadharId;
        this._gs.getCall(url)
          .subscribe(
            (res) => {
              console.log(res);
              if (res['success'] == true) {
                this.onAddAadharImage();
              }
            },
            err => {
              console.log(err);
            }
          )
      }
    } else {
      this.onEditVathiyar(this.vathiyarForm);
    }
  }

  checkUserExistence(form: NgForm) {
    // console.log(this.addVathiyarData);
    if (form.value.phoneNo && form.value.email) {
      let url = environment.apiUrl + environment.url.CheckUserExistence;
      let reqData = {
        "EMAIL_ID": form.value.email,
        "CONTACT_NO": form.value.phoneNo
      }
      this._gs.postCall(url, reqData)
        .subscribe(res => {
          if (res.msgCode === "SUCC_MSG_45") {
            this.onAddImage();
          }
        },
          err => {
            console.log(err.error.msgCode);
            if (err.error.msgCode === "ERR_MSG_3") {
              this._gs.showErrorToast(err.error.msg, "Error");
            } else if (err.error.msgCode === "ERR_MSG_11") {
              this._gs.showErrorToast(err.error.msg, "Error");
            }
          })
    }
  }

  onAddImage() {
    if (this.selectedProfileImgFile) {
      let url = environment.imageServerUrl + environment.url.newImg;
      let formData = new FormData();
      formData.append('caption', this.selectedProfileImgFile.name);
      formData.append('file', this.selectedProfileImgFile);
      this._gs.postCall(url, formData)
        .subscribe(
          res => {
            console.log(res);
            if (res['success']) {
              this._val.PROFILE_IMAGE = res['image'];
              if (!this.isAddMode) {
                this.onAddAadharImage();
              } else {
                if (this.selectedAadharImgFile) {
                  this.onAddAadharImage();
                } else { this.onEditVathiyar(this.vathiyarForm); }
              }

            } else {
              this._gs.showErrorToast('Profile ' + res.message, "Error");
            }
          },
          err => {
            console.log(err);
          }
        )
    } else{
      this.profileImgPresence = "Upload a profile image";
      this.aadharImgPresence = "Upload an aadhar image";
    }
  }

  onAddAadharImage() {
    if (this.selectedAadharImgFile) {
      let url = environment.imageServerUrl + environment.url.newImg;
      let formData = new FormData();
      formData.append('caption', this.selectedAadharImgFile.name);
      formData.append('file', this.selectedAadharImgFile);
      this._gs.postCall(url, formData)
        .subscribe(
          res => {
            if (res['success']) {
              this._val.AADHAR_IMAGE = res['image'];
              this.isAddMode ? this.onAddVathiyar(this.vathiyarForm) : this.onEditVathiyar(this.vathiyarForm);
            } else {
              this._gs.showErrorToast('Aadhaar ' + res.message, "Error");
            }
          },
          err => {
            console.log(err);
          }
        )
    }
    else {
      this.onEditVathiyar(this.vathiyarForm);
    }
  }

  onAddVathiyar(form: NgForm) {
    console.log('inside add vathiyar');
    let url = environment.apiUrl + environment.url.AddVathiyar;
    let reqAddVathiyardata = {
      "USER_TYPE": "Vathiyar",
      "FIRST_NAME": form.value.fname,
      "LAST_NAME": form.value.lname,
      "EMAIL_ID": form.value.email,
      "AADHAAR_NUMBER": form.value.aadharNumber,
      "PROFILE_IMAGE": this._val.PROFILE_IMAGE ? this._val.PROFILE_IMAGE : '',
      "AADHAAR_CARD_IMAGE": this._val.AADHAR_IMAGE ? this._val.AADHAR_IMAGE : '',
      "LOCATION_ADDRESS": form.value.address,
      "LOCATION_ADDRESS_COUNTRY": form.value.country,
      "LOCATION_ADDRESS_STATE": form.value.state,
      "LOCATION_ADDRESS_CITY": form.value.district,
      "LOCATION_ADDRESS_PINCODE": form.value.pincode,
      "CONTACT_NO": form.value.phoneNo,
      "SERVICES": this.serviceSelectedItems,
      "LANGUAGE_ID": this.langSelectedItems,
      "LOCATION_PREFERENCE": this.locationSelectedItems,
      "COMMUNITY_ID": this.communitySelectedItems,
      "NAME_OF_BANK": form.value.bankName,
      "BANK_ACC_NO": form.value.bankAccNo,
      "BANK_IFSC_CODE": form.value.bankIFSCCode,
      "UPI_ID": form.value.UPIID,
      "BANK_ACC_TYPE_ID": form.value.bankAccType,
      "UPI_NAME": form.value.UPIName,
      "CREATED_BY": this._val.USER_ID
    }

    // Http call for add vathiyar
    this._gs.postCall(url, reqAddVathiyardata)
      .subscribe(
        res => {
          if (res.msgCode === "SUCC_MSG_9") {
            this._router.navigate(['/vathiyars']);
            this._gs.showSuccessToast(res['msg'], "Success");
          } else if (res.msgCode === "ERR_MSG_3") {
            this._gs.showErrorToast(res['msg'], "Success");
          } else if (res.msgCode === "ERR_MSG_11") {
            this._gs.showErrorToast(res['msg'], "Success");
          }
        },
        err => {
          console.log(err);
        }
      )

  }

  onEditVathiyar(form: NgForm) {
    console.log('inside edit vathiyar');
    let url = environment.apiUrl + environment.url.EditVathiyar + this._val.EDIT_VATHIYAR_DATA.VATHIYAR_ID;
    let reqEditVathiyardata = {
      "USER_TYPE": "Vathiyar",
      "FIRST_NAME": form.value.fname,
      "LAST_NAME": form.value.lname,
      "EMAIL_ID": form.value.email,
      "PROFILE_IMAGE": this._val.PROFILE_IMAGE ? this._val.PROFILE_IMAGE : this._val.EDIT_VATHIYAR_DATA.PROFILE_IMAGE,
      "AADHAAR_CARD_IMAGE": this._val.AADHAR_IMAGE ? this._val.AADHAR_IMAGE : this._val.EDIT_VATHIYAR_DATA.AADHAR_IMAGE,
      "AADHAAR_NUMBER": form.value.aadharNumber,
      "LOCATION_ADDRESS": form.value.address,
      "LOCATION_ADDRESS_COUNTRY": form.value.country,
      "LOCATION_ADDRESS_STATE": form.value.state,
      "LOCATION_ADDRESS_CITY": form.value.district,
      "LOCATION_ADDRESS_PINCODE": form.value.pincode,
      "CONTACT_NO": form.value.phoneNo,
      "SERVICES": this.serviceSelectedItems,
      "LANGUAGE_ID": this.langSelectedItems,
      "LOCATION_PREFERENCE": this.locationSelectedItems,
      "COMMUNITY_ID": this.communitySelectedItems,
      "NAME_OF_BANK": form.value.bankName,
      "BANK_ACC_NO": form.value.bankAccNo,
      "BANK_IFSC_CODE": form.value.bankIFSCCode,
      "UPI_ID": form.value.UPIID,
      "BANK_ACC_TYPE_ID": form.value.bankAccType,
      "UPI_NAME": form.value.UPIName,
      "CREATED_BY": this._val.USER_ID
    }

    // Http call for add vathiyar
    this._gs.patchCall(url, reqEditVathiyardata)
      .subscribe(
        res => {
          if (res.msgCode === "SUCC_MSG_43") {
            this._router.navigate(['/vathiyars']);
            this._gs.showSuccessToast(res['msg'], "Success");
            this._val.onCancel();
          }
        },
        err => {
          console.log(err);
        }
      )
  }

  public onCancelVathiyar() {
    this._val.onCancel();
    this._router.navigate(['/vathiyars']);
  }

}
