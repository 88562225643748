<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
    <div class="page-head">
        <h3 *ngIf="isAddMode" class="page-title">
            <i class="icon-back mr-1"></i> {{ 'COMMUNITY.ADD_COMMUNITY' | translate }}
        </h3>
        <h3 *ngIf="!isAddMode" class="page-title">
            <i class="icon-back mr-1"></i> {{ 'COMMUNITY.EDIT_COMMUNITY' | translate }}
        </h3>
    </div>
    <form class="form-box form-block inner-block mt-2" (ngSubmit)="f.form.valid && onAddCommunity(f)" #f="ngForm"
        novalidate>
        <div class="card">
            <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                <div class="form-col mr-1">
                    <label class="form-label"> {{ 'COMMUNITY.COMMUNITY_NAME' | translate }}</label>
                    <div class="form-group">
                        <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditCommunityData.communityName" id="communityName" #communityName="ngModel"
                            required name="communityName" placeholder="">
                        <div *ngIf="communityName.errors && (communityName.touched || f.submitted)">
                            <div class="error_message" *ngIf="communityName.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter community name</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                <div class="form-col mr-1">
                    <label class="form-label"> {{ 'COMMUNITY.COMMUNITY_CODE' | translate }}</label>
                    <div class="form-group">
                        <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditCommunityData.communityCode" id="communityCode" #communityCode="ngModel"
                            required name="communityCode" placeholder="Enter two letter community code" maxlength="2">
                        <div *ngIf="communityCode.errors && (communityCode.touched || f.submitted)">
                            <div class="error_message" *ngIf="communityCode.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter two letter community code</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-col">
                    <label class="form-label"> {{ 'COMMUNITY.DESCRIPTION' | translate }}</label>
                    <div class="form-group">
                        <textarea type="text" class="form-control form-width-half textarea"
                            [ngModelOptions]="{ updateOn: 'blur' }" [(ngModel)]="addEditCommunityData.communityDesc"
                            name="communityDesc" id="communityDesc"
                            #communityDesc="ngModel" required placeholder=""></textarea>
                        <div *ngIf="communityDesc.errors && (communityDesc.touched || f.submitted)">
                            <div class="error_message" *ngIf="communityDesc.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter community description</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row d-flex flex-wrap form-action">
                <div class="form-col">
                    <a (click)="onCancelCommunity()" class="btn btn-secondary btn-medium">{{ 'BUTTONS.BTN_CANCEL' | translate }}</a>
                    <button type="submit" *ngIf="isAddMode" [disabled]="!f.form.valid"
                        class="btn btn-brand btn-medium">{{ 'BUTTONS.BTN_ADD_COMMUNITY' | translate }}</button>
                    <button type="button" *ngIf="!isAddMode" [disabled]="!f.form.valid"
                        (click)="f.form.valid && onEditCommunity(f)" class="btn btn-brand btn-medium">{{'BUTTONS.BTN_EDIT_COMMUNITY' | translate }}</button>
                </div>
            </div>
        </div>
    </form>

</section>