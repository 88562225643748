<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
   <section class="page-head">
      <h3 class="page-title">Dashboard</h3>
   </section>
   <div class="row">

    <!-- End XP Col -->
    <div class="col col-md-3 col-sm-6">
        <div class="card m-b-30">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="float-left">
                        <h4 class="xp-counter text-success">80</h4>
                        <p class="mb-0 text-muted">Open Bookings</p>
                    </div>
                    <div class="float-right">
                        <div class="xp-widget-icon xp-widget-icon-bg bg-success-rgba">
                            <i class="icon-ic_notes icon-4x text-success"></i> 
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- End XP Col -->

    <!-- End XP Col -->
    <div class="col col-md-3 col-sm-6">
        <div class="card m-b-30">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="float-left">
                        <h4 class="xp-counter text-primary">16</h4>
                        <p class="mb-0 text-muted">Pending</p>
                    </div>
                    <div class="float-right">
                        <div class="xp-widget-icon xp-widget-icon-bg bg-primary-rgba">
                            <i class="icon-ic_notes icon-4x text-primary"></i> 
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- End XP Col -->

    <!-- End XP Col -->
    <div class="col col-md-3 col-sm-6">
        <div class="card m-b-30">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="float-left">
                        <h4 class="xp-counter text-warning">3</h4>
                        <p class="mb-0 text-muted">In Progress</p>
                    </div>
                    <div class="float-right">
                        <div class="xp-widget-icon xp-widget-icon-bg bg-warning-rgba">
                            <i class="icon-ic_notes icon-4x text-warning"></i> 
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- End XP Col -->

    <!-- End XP Col -->
    <div class="col col-md-3 col-sm-6">
        <div class="card m-b-30">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="float-left">
                        <h4 class="xp-counter text-danger">5</h4>
                        <p class="mb-0 text-muted">Cancelled</p>
                    </div>
                    <div class="float-right">
                        <div class="xp-widget-icon xp-widget-icon-bg bg-danger-rgba">
                            <i class="icon-ic_notes icon-4x text-danger"></i> 
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- End XP Col -->

</div>
<div class="row">
    <div class="col col-md-8 col-sm-12">
        <div class="card mt-3">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="d-flex justify-content-between align-items">
                        <h4 class="widget-header mb-0">Income Revenue</h4>
                        <a class="btn-link">View more</a>
                    </div>
                        
                        
                </div>
            </div>
        </div>
    </div>
    <div class="col col-md-4 col-sm-12">
        <div class="card mt-3">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="d-flex justify-content-between align-items">
                        <h4 class="widget-header mb-0">Total Sales</h4>
                        <a class="btn-link">View more</a>
                    </div>
                        
                        
                </div>
            </div>
        </div>
    </div>
   
   
</div>
<div class="row">
    <div class="col col-md-6 col-sm-12">
        <div class="card mt-3">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="d-flex justify-content-between align-items">
                        <h4 class="widget-header mb-0">Top 5 Vathiyars</h4>
                        <a class="btn-link">View more</a>
                    </div>
                    <ul class="list-group list-border">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                <span class="user-avatar mr-1"><img src="assets/images/users/avatar-5.jpg" width="30" alt="user-image" class="rounded-circle"></span> 
                                   <span class="list-details"> 
                                       <p class="title">Venu Shastri </p>
                                       <span> Mylapore, Chennai</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">4.5 (130 Reviews)</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                <span class="user-avatar mr-1"><img src="assets/images/users/avatar-5.jpg" width="30" alt="user-image" class="rounded-circle"></span> 
                                   <span class="list-details"> 
                                       <p class="title">Venu Shastri </p>
                                       <span> Mylapore, Chennai</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">4.5 (130 Reviews)</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                <span class="user-avatar mr-1"><img src="assets/images/users/avatar-5.jpg" width="30" alt="user-image" class="rounded-circle"></span> 
                                   <span class="list-details"> 
                                       <p class="title">Venu Shastri </p>
                                       <span> Mylapore, Chennai</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">4.5 (130 Reviews)</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                <span class="user-avatar mr-1"><img src="assets/images/users/avatar-5.jpg" width="30" alt="user-image" class="rounded-circle"></span> 
                                   <span class="list-details"> 
                                       <p class="title">Venu Shastri </p>
                                       <span> Mylapore, Chennai</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">4.5 (130 Reviews)</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                <span class="user-avatar mr-1"><img src="assets/images/users/avatar-5.jpg" width="30" alt="user-image" class="rounded-circle"></span> 
                                   <span class="list-details"> 
                                       <p class="title">Venu Shastri </p>
                                       <span> Mylapore, Chennai</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">4.5 (130 Reviews)</span>
                        </li>
                      </ul>
                        
                </div>
            </div>
        </div>
    </div>
    <div class="col col-md-6 col-sm-12">
        <div class="card mt-3">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="d-flex justify-content-between align-items">
                        <h4 class="widget-header mb-0">Top 5 Services</h4>
                        <a class="btn-link">View more</a>
                    </div>
                    <ul class="list-group list-border">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                   <span class="list-details"> 
                                       <p class="title">Ganapathy Homam </p>
                                       <span> Short description</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">Rs. 7,400</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                   <span class="list-details"> 
                                       <p class="title">Santhana gopala homam</p>
                                       <span> Short description</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">Rs. 4,300</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                   <span class="list-details"> 
                                       <p class="title">Ganapathy Homam </p>
                                       <span> Short description</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">Rs. 7,400</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                   <span class="list-details"> 
                                       <p class="title">Ganapathy Homam </p>
                                       <span> Short description</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">Rs. 7,400</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="list-content d-flex">
                                   <span class="list-details"> 
                                       <p class="title">Ganapathy Homam </p>
                                       <span> Short description</span>
                                </span>
                            </div>
                          <span class="badge bg-primary rounded-pill">Rs. 7,400</span>
                        </li>
                      
                      </ul> 
                        
                </div>
            </div>
        </div>
    </div>
   
   
</div>
<div class="card mt-3">
    <div class="card-body">
        <div class="xp-widget-box">
            <div class="d-flex justify-content-between align-items">
                <h4 class="widget-header mb-0">Invoice Summary</h4>
                <a class="btn-link">View more</a>
            </div>

        </div>
<table class="table">
    <thead>
       <tr>
          <th>Invoice #</th>
          <th>Summary</th>
          <th>Date</th>
          <th>Total Amount</th>
          <th>Transaction Type</th>
          <th>Invoice Generated</th>
          <th colspan="2"></th>
       </tr>
    </thead>
    <tbody>
        <tr>
          <td data-label="Invoice #">#789021</td>
          <td data-label="Summary">Ganapathy Homam</td>
          <td data-label="Date">Nov 5, 2020</td>
          <td data-label="Total Amount">Rs. 7,400</td>
          <td data-label="Transaction Type">Cash</td>
          <td data-label="Invoice Generated"><i class="icon-ic_notes ic-align-middle"></i><a class="btn-link"> Download</a></td>
          </tr> 
          <tr>
            <td data-label="Invoice #">#789021</td>
            <td data-label="Summary">Ganapathy Homam</td>
            <td data-label="Date">Nov 5, 2020</td>
            <td data-label="Total Amount">Rs. 7,400</td>
            <td data-label="Transaction Type">Cash</td>
            <td data-label="Invoice Generated"><i class="icon-ic_notes ic-align-middle"></i><a class="btn-link"> Download</a></td>
            </tr> 
            <tr>
                <td data-label="Invoice #">#789021</td>
                <td data-label="Summary">Ganapathy Homam</td>
                <td data-label="Date">Nov 5, 2020</td>
                <td data-label="Total Amount">Rs. 7,400</td>
                <td data-label="Transaction Type">Cash</td>
                <td data-label="Invoice Generated"><i class="icon-ic_notes ic-align-middle"></i><a class="btn-link"> Download</a></td>
                </tr> 
      
    </tbody>
 </table>
 </div>
 </div>

   </section>
