<!-- Login -->
<div class="auth-container">
    <div class="auth-banner illustration"></div>
    <div class="auth-box medium-box d-flex flex-wrap align-items-center justify-space-between">
        <span class="form-inline lang-dropdown">
            <select class="form-control" #selectedLang (change)="switchLang(selectedLang.value)">
              <option *ngFor="let language of translate.getLangs()" [value]="language"
                [selected]="language === translate.currentLang">
                {{ language }}
              </option>
            </select>
        </span>
        <form class="form-box" (ngSubmit)="f7.form.valid && onSubmitLogin(f7)" #f7="ngForm"
        novalidate>
            <div class="brand-logo">
                <img src="assets/images/vathiyar-logo.jpg" alt="Vaathiyar" />
            </div>
            <h2 class="mb-2">{{'LOGIN_PAGE.HD_TITLE'| translate }}</h2>
            <h4 *ngIf="msg" class="text-center font-weight-bold mb-1 text-danger">{{msg}}</h4>
            <div class="form-row pb-1">
                <label class="form-label"> {{'LOGIN_PAGE.LBL_EMAIL'| translate }} </label>
                <div class="form-group">
                    <!-- <i>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" fill="none" viewBox="0 0 28 29">
                            <path stroke="#393939" stroke-linecap="round" stroke-linejoin="round" d="M24 8.993c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2m20 0v12c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-12m20 0l-10 7-10-7"></path>
                        </svg>
                    </i> -->
                    <input type="email"  class="form-control full-width"  
                        [ngModelOptions]="{ updateOn: 'blur' }" [(ngModel)]="loginUserData.email" name="email"  
                        id="email" #email="ngModel" required 
                        placeholder="{{'LOGIN_PAGE.PH_EMAIL'| translate }}" 
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" >
                        <div *ngIf="email.errors && (email.touched || f7.submitted)">
                            <div class="error_message" *ngIf="email.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Email is a required field! </i>
                            </div>
                            <div class="error_message" *ngIf="email.errors?.pattern">
                                <span class="e_arrow"></span>
                                <i>This is not a valid Email!!!</i>
                            </div>                            
                        </div>
                </div>
            </div>
            <div class="form-row">
                <label class="form-label">{{'LOGIN_PAGE.LBL_PWD' | translate }} </label>
                <div class="form-group">
                    <!-- <i>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" fill="none" viewBox="0 0 28 29">
                            <path stroke="#393939" stroke-linecap="round" stroke-linejoin="round" d="M9 13.415v-4a5 5 0 1110 0v4m-12 0h14a2 2 0 012 2v7a2 2 0 01-2 2H7a2 2 0 01-2-2v-7a2 2 0 012-2z"></path>
                        </svg>
                    </i> -->
                    <input type="password" class="form-control full-width" [ngModelOptions]="{ updateOn: 'blur' }" 
                        [(ngModel)]="loginUserData.password" id="password" #password="ngModel"
                        required name="password" placeholder="{{'LOGIN_PAGE.PH_PWD' | translate }}"
                        minlength="6"
                        maxlength="40">
                        <div *ngIf="password.errors && (password.touched || f7.submitted)">
                            <div class="error_message" *ngIf="password.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter the password</i>
                            </div>
                            <div class="error_message" *ngIf="password.errors?.minlength">
                                <span class="e_arrow"></span>
                                <i>Password must be at least 6 characters</i>
                            </div>
                            <div class="error_message" *ngIf="password.errors?.maxlength">
                                <span class="e_arrow"></span>
                                <i>Password must not exceed 40 characters</i>
                            </div>
                        </div>
                </div>
            </div>
            <div class="form-row">
                <!-- <button (click)="onSubmitLogin()"  class="btn btn-brand btn-block">{{'BUTTONS.BTN_LOGIN' | translate }}</button> -->
                <br/>
                <button type="submit" class="btn btn-brand btn-block" [disabled]="!f7.form.valid">
                    {{'BUTTONS.BTN_LOGIN' | translate }}
                </button>
                <!--  routerLink="/dashboard" <p class="text-center">Don't have an account <a class="btn-link" href="/register">Signup ?</a> -->
            </div>
        </form>
    </div>
    </div>
    <!-- End Login -->