<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
        <div class="page-head">
            <h3 *ngIf="isAddMode" class="page-title">
                <i class="icon-back mr-1"></i> {{ 'LOCATION.ADD_LOCATION' | translate }}
            </h3>
            <h3 *ngIf="!isAddMode" class="page-title">
                <i class="icon-back mr-1"></i> {{ 'LOCATION.EDIT_LOCATION' | translate }}
            </h3>
        </div>
        <form class="form-box form-block inner-block mt-2" (ngSubmit)="f3.form.valid && onAddLocation(f3)" #f3="ngForm"
        novalidate>
            <div class="card">
                <div class="form-row">
                    <div class="form-col mr-1">
                        <label class="form-label"> {{ 'LOCATION.LOCATION_NAME' | translate }}</label>
                        <div class="form-group">
                            <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditLocationData.locationName" id="locationName" #locationName="ngModel"
                            required name="locationName" placeholder="">
                            <div *ngIf="locationName.errors && (locationName.touched || f3.submitted)">
                                <div class="error_message" *ngIf="locationName.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter a location name</i>
                                </div>
                            </div>
                            <!-- <input [(ngModel)]="addEditLocationData.locationName" name="locationName"  class="form-control form-modal" type="text" placeholder=""> -->
                        </div>
                    </div>

                 
                </div>
                <div class="form-row">
                    <div class="form-col mr-1">
                        <label class="form-label"> {{ 'LOCATION.CODE' | translate }}</label>
                        <div class="form-group">
                            <input type="number" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditLocationData.locationPincode" id="locationPincode"
                            #locationPincode="ngModel" required name="locationPincode" placeholder="Enter 6 digit pincode" max="999999">
                            <div *ngIf="locationPincode.errors && (locationPincode.touched || f3.submitted)">
                                <div class="error_message" *ngIf="locationPincode.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter the 6 digit pincode</i>
                                </div>
                            </div>
                            <!-- <input  [(ngModel)]="addEditLocationData.locationPincode" name="locationPincode" class="form-control form-modal" type="number" placeholder="Enter 6 digit pincode" max="999999"> -->
                        </div>
                    </div>

                 
                </div>
               
               
                <div class="form-row">
                    <div class="form-col">
                        <label class="form-label">  {{ 'LOCATION.DESCRIPTION' | translate }}</label>
                        <div class="form-group">
                            <textarea type="text" class="form-control form-width-half textarea"
                            [ngModelOptions]="{ updateOn: 'blur' }" [(ngModel)]="addEditLocationData.locationDesc"
                            name="locationDesc" id="locationDesc" #locationDesc="ngModel" required
                            placeholder=""></textarea>
                            <div *ngIf="locationDesc.errors && (locationDesc.touched || f3.submitted)">
                                <div class="error_message" *ngIf="locationDesc.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter location description</i>
                                </div>
                            </div>
                            <!-- <textarea [(ngModel)]="addEditLocationData.locationDesc" name="locationDesc" class="form-control form-width-half textarea" type="text" placeholder=""></textarea> -->
                        </div>
                    </div>
                </div>
            



            <div class="form-row d-flex flex-wrap form-action">
                <div class="form-col">
                    <a (click)="onCancelLocation()" class="btn btn-secondary btn-medium">{{ 'BUTTONS.BTN_CANCEL' | translate }}</a>
                    <!-- <a routerLink="/orders" class="btn btn-brand btn-medium">Add Location</a> -->
                    <button type="submit" *ngIf="isAddMode" [disabled]="!f3.form.valid" class="btn btn-brand btn-medium">{{ 'BUTTONS.BTN_ADD_LOCATION' | translate }}</button>
                    <button type="button" *ngIf="!isAddMode" [disabled]="!f3.form.valid" (click)="f3.form.valid && onEditLocation(f3)" class="btn btn-brand btn-medium">{{ 'BUTTONS.BTN_EDIT_LOCATION' | translate }}</button>
                </div>
            </div>
        </div>
        </form>
    
</section>