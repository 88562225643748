import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { HttpGlobalService } from 'src/app/services/httpglobal.service';
import { ValuesService } from 'src/app/services/values.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-addinclusion',
  templateUrl: './addinclusion.component.html',
  styleUrls: ['./addinclusion.component.scss']
})
export class AddinclusionComponent implements OnInit {

  public addEditInclusionData: any = {};
  public isAddMode: boolean;

  constructor(public translate: TranslateService,
    private _gs: HttpGlobalService,
    public formBuilder: FormBuilder,
    private _router: Router,
    private _val: ValuesService) {
    this.isAddMode = this._val.isAddMode;
  }

  public ngOnInit(): void {
    if (this._val.EDIT_INCLUSION_DATA) {
      this.addEditInclusionData.inclusionName = this._val.EDIT_INCLUSION_DATA.INCLUSIONS_NAME;
      this.addEditInclusionData.inclusionPrice = this._val.EDIT_INCLUSION_DATA.INCLUSIONS_PRICE;
      this.addEditInclusionData.inclusionDesc = this._val.EDIT_INCLUSION_DATA.INCLUSIONS_DESC;
    }
  }
  @ViewChild('f1') inclusionForm: NgForm;

  public onAddInclusion(form: NgForm) {
    let url = environment.apiUrl + environment.url.AddInclusion;
    let reqAddInclusionData = {
      "INCLUSIONS_NAME": form.value.inclusionName,
      "INCLUSIONS_PRICE": form.value.inclusionPrice,
      "INCLUSIONS_DESC": form.value.inclusionDesc,
      "CREATED_BY": this._val.USER_ID
    }

    this._gs.postCall(url, reqAddInclusionData)
      .subscribe(
        res => {
          console.log(res);
          if (res.msgCode === "SUCC_MSG_30") {
            this._router.navigate(['/inclusion']);
            this._gs.showSuccessToast(res['msg'], "Success");
          }
        },
        err => {
          console.log(err);
        }
      )
  }

  public onEditInclusion(form: NgForm) {
    let url = environment.apiUrl + environment.url.EditInclusion + this._val.EDIT_INCLUSION_DATA.INCLUSIONS_ID;
    let reqEditInclusionData = {
      "INCLUSIONS_NAME": form.value.inclusionName,
      "INCLUSIONS_PRICE": form.value.inclusionPrice,
      "INCLUSIONS_DESC": form.value.inclusionDesc,
      "UPDATED_BY": this._val.USER_ID
    }
    this._gs.patchCall(url, reqEditInclusionData)
      .subscribe(
        res => {
          console.log(res);
          if (res.msgCode === "SUCC_MSG_31") {
            this._router.navigate(['/inclusion']);
            this._val.EDIT_INCLUSION_DATA = '';
            this._val.isAddMode = true;
            this._gs.showSuccessToast(res['msg'], "Success");
          }
        },
        err => {
          console.log(err);
        }
      )
  }

  public onCancelInclusion() {
    this._val.onCancel();
    this._router.navigate(['/inclusion']);
  }

}
