<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
        <div class="page-head">
            <h3 *ngIf="isAddMode" class="page-title">
                <i class="icon-back mr-1"></i> {{ 'SERVICES.ADD_SERVICE' | translate }}
            </h3>
            <h3 *ngIf="!isAddMode" class="page-title">
                <i class="icon-back mr-1"></i> {{ 'SERVICES.EDIT_SERVICE' | translate }}
            </h3>
        </div>
        <form class="form-box form-block inner-block mt-2" (ngSubmit)="f6.form.valid && onAddServiceImage(f6)" #f6="ngForm"
        novalidate enctype="multipart/form-data">
            <div class="card">
                
                <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                    <div class="form-col">
                        <div class="label-group d-flex flex-wrap align-items-center justify-space-between">
                            <label class="form-label d-flex"> {{ 'SERVICES.CATEGORY' | translate }} <sup
                     class="text-danger mandatory-key">*</sup></label>
                            <!-- <a href="#" data-target="showFilePanel" class="btn-link"><i class="icon-add icon-3x vertical-middle"></i>Add </a> -->
                        </div>
                        <div class="form-group">
                            <select [(ngModel)]="addServiceData.categoryType" [ngModelOptions]="{ updateOn: 'blur' }" 
                                name="categoryType" id="categoryType" #categoryType="ngModel" required  class="form-control form-base form-modal minimal">
                                <option [ngValue]="category.SERVICE_CAT_ID" *ngFor="let category of categories">{{category.SERVICE_CAT_NAME }}</option>
                                <!-- <option>Select Category</option>
                                <option>Homam</option>
                                <option>Pooja</option> -->
                            </select>
                            <div *ngIf="categoryType.errors && (categoryType.touched || f6.submitted)">
                                <div class="error_message" *ngIf="categoryType.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please select a service category type </i>
                                </div>
                            </div>                           

                        </div>
                    </div>
                </div>
                <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                    <div class="form-col mr-1">
                        <label class="form-label"> {{ 'SERVICES.SERVICE_NAME' | translate }}<sup
                     class="text-danger mandatory-key">*</sup></label>
                        <div class="form-group">
                            <input [(ngModel)]="addServiceData.name" name="name" class="form-control form-modal" 
                            type="text" placeholder="" [ngModelOptions]="{ updateOn: 'blur' }"
                            id="name" #name="ngModel" required >
                            <div *ngIf="name.errors && (name.touched || f6.submitted)">
                                <div class="error_message" *ngIf="name.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter a service name</i>
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div class="form-col">
                        <label class="form-label"> {{ 'SERVICES.PRICE' | translate }}<sup
                     class="text-danger mandatory-key">*</sup></label>
                        <div class="form-group">
                            <input [(ngModel)]="addServiceData.price" [ngModelOptions]="{ updateOn: 'blur' }" name="price" 
                            class="form-control form-base" type="text" placeholder="" id="price" #price="ngModel"
                            required>

                            <div *ngIf="price.errors && (price.touched || f6.submitted)">
                                <div class="error_message" *ngIf="price.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter the service price</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                        <label class="form-label">{{ 'SERVICES.SERVICE_IMAGE' | translate  }} <span class="help-text justify-content-end">(JPG and PNG with max size 5 MB)</span><sup
                     class="text-danger mandatory-key">*</sup></label>
                        <input type="file" (change)="onSelectFile($event)" accept="image/*">
                        <div *ngIf="imageUrl"><img [src]="imageUrl" height="100" width="100"></div>
                    </div>
                </div>
                <!-- <div class="form-row">
                    <div class="form-col">
                       <label class="form-label">{{ 'SERVICES.SERVICE_IMAGE' | translate  }} <span class="help-text justify-content-end">(JPG and PNG with max size 5 MB)</span><sup
                     class="text-danger mandatory-key">*</sup></label>
                        <div class="dropzone dz-clickable medium">
                            
                            <div class="dz-default dz-message">
                                {{imageError}}
                                <div class="">
                                    <label class="custom-file-upload"> 
                                        browse
                                        <input type="file" (change)="fileChangeEvent($event)" 
                                                            class="fileInputProfile">
                                    <sup
                     class="text-danger mandatory-key">*</sup></label>    
                                    <ng-container *ngIf="isImageSaved; else elseTemplate">
                                        <img [src]="cardImageBase64" /> 
                                        <a class="btn btn-danger" (click)="removeImage()" *ngIf="isImageSaved">Remove</a>
                                    </ng-container>
                                    <ng-template #elseTemplate>
                                    </ng-template>
                                    
                                </div>
                            </div>
                            
                       </div>
                    </div>
                </div> -->

                <div class="form-row">
                    <div class="form-col">
                        <label class="form-label"> {{ 'SERVICES.DESCRIPTION' | translate  }}<sup
                     class="text-danger mandatory-key">*</sup></label>
                        <div class="form-group">
                            <!-- <textarea [(ngModel)]="addServiceData.desc" name="desc" class="form-control form-width-half textarea" type="text" placeholder=""></textarea> -->

                            <textarea type="text" class="form-control form-width-half textarea" 
                            [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addServiceData.desc" id="desc" #desc="ngModel"
                            required name="desc" placeholder=""></textarea>
                            <div *ngIf="desc.errors && (desc.touched || f6.submitted)">
                                <div class="error_message" *ngIf="desc.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter the service description</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                        <label class="form-label"> {{ 'SERVICES.SHORT_DESC' | translate  }}<sup
                     class="text-danger mandatory-key">*</sup></label>
                        <div class="form-group">
                            <!-- <textarea [(ngModel)]="addServiceData.shortDesc" name="shortDesc" class="form-control form-width-half textarea" type="text" placeholder=""></textarea> -->

                            <textarea type="text" class="form-control form-width-half textarea" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addServiceData.shortDesc" id="shortDesc" #shortDesc="ngModel"
                            required name="shortDesc" placeholder=""></textarea>
                            <div *ngIf="shortDesc.errors && (shortDesc.touched || f6.submitted)">
                                <div class="error_message" *ngIf="shortDesc.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter the service short description</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                        <label class="form-label"> {{ 'SERVICES.KEYINSIGHTS' | translate  }}<sup
                     class="text-danger mandatory-key">*</sup></label>
                        <div class="form-group">
                            <!-- <textarea [(ngModel)]="addServiceData.keyInsights" name="keyInsights" class="form-control form-width-half textarea" type="text" placeholder=""></textarea> -->

                            <textarea type="text" class="form-control form-width-half textarea" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addServiceData.keyInsights" id="keyInsights" #keyInsights="ngModel"
                            required name="keyInsights" placeholder=""></textarea>
                            <div *ngIf="keyInsights.errors && (keyInsights.touched || f6.submitted)">
                                <div class="error_message" *ngIf="keyInsights.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter the service keyinsights</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                        <label class="form-label"> {{ 'SERVICES.PROMISE' | translate  }}<sup
                     class="text-danger mandatory-key">*</sup></label>
                        <div class="form-group">
                            <!-- <textarea [(ngModel)]="addServiceData.promise" name="promise" class="form-control form-width-half textarea" type="text" placeholder=""></textarea> -->

                            <textarea type="text" class="form-control form-width-half textarea" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addServiceData.promise" id="promise" #promise="ngModel"
                            required name="promise" placeholder=""></textarea>
                            <div *ngIf="promise.errors && (promise.touched || f6.submitted)">
                                <div class="error_message" *ngIf="promise.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter the service promises</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                    <div class="form-col">
                        <div class="label-group d-flex flex-wrap align-items-center justify-space-between">
                            <label class="form-label d-flex"> {{ 'SERVICES.PACKAGE' | translate }} <sup
                     class="text-danger mandatory-key">*</sup></label>
                            <!-- <a href="#" data-target="showFilePanel" class="btn-link"><i class="icon-add icon-3x vertical-middle"></i>Add </a> -->
                        </div>
                        <div class="form-group">
                            <select [(ngModel)]="addServiceData.packageId" [ngModelOptions]="{ updateOn: 'blur' }" 
                                name="packageId" id="packageId" #packageId="ngModel" required  
                                class="form-control form-base form-modal minimal">
                                <option [ngValue]="package.PACKAGE_ID" *ngFor="let package of packages">{{package.PACKAGE_NAME }}</option>
                                <!-- <option>Select Category</option>
                                <option>Homam</option>
                                <option>Pooja</option> -->
                            </select>
                            <div *ngIf="packageId.errors && (packageId.touched || f6.submitted)">
                                <div class="error_message" *ngIf="packageId.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please select the package </i>
                                </div>
                            </div>

                            <!-- <select [(ngModel)]="addServiceData.packageId" name="packageId" 
                            class="form-control form-base form-modal minimal">
                                <option [ngValue]="package.PACKAGE_ID" *ngFor="let package of packages">{{package.PACKAGE_NAME }}</option>
                            </select> -->
                        </div>
                    </div>
                </div>
                <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                    <div class="form-col">
                        <div class="label-group d-flex flex-wrap align-items-center justify-space-between">
                            <label class="form-label d-flex"> {{ 'SERVICES.INCLUSION' | translate }} <sup
                     class="text-danger mandatory-key">*</sup></label>
                            <!-- <a href="#" data-target="showFilePanel" class="btn-link"><i class="icon-add icon-3x vertical-middle"></i>Add </a> -->
                        </div>
                        <div class="form-group">
                            <select [(ngModel)]="addServiceData.inclusionId" [ngModelOptions]="{ updateOn: 'blur' }" 
                                name="inclusionId" id="inclusionId" #inclusionId="ngModel" required  
                                class="form-control form-base form-modal minimal">
                                <option [ngValue]="inclusion.INCLUSIONS_ID" *ngFor="let inclusion of inclusions">{{inclusion.INCLUSIONS_NAME }}</option>
                                <!-- <option>Select Category</option>
                                <option>Homam</option>
                                <option>Pooja</option> -->
                            </select>
                            <div *ngIf="inclusionId.errors && (inclusionId.touched || f6.submitted)">
                                <div class="error_message" *ngIf="inclusionId.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please select the inclusion </i>
                                </div>
                            </div>
<!-- 
                            <select [(ngModel)]="addServiceData.inclusionId" name="inclusionId" class="form-control form-base form-modal minimal">
                                <option [ngValue]="inclusion.INCLUSIONS_ID" *ngFor="let inclusion of inclusions">{{inclusion.INCLUSIONS_NAME }}</option>
                            </select> -->
                        </div>
                    </div>
                </div>
                <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                    <div class="form-col">
                        <div class="label-group d-flex flex-wrap align-items-center justify-space-between">
                            <label class="form-label d-flex"> {{ 'SERVICES.LOCATION' | translate }} <sup
                     class="text-danger mandatory-key">*</sup></label>
                            <!-- <a href="#" data-target="showFilePanel" class="btn-link"><i class="icon-add icon-3x vertical-middle"></i>Add </a> -->
                        </div>
                        <div class="form-group">
                            <select [(ngModel)]="addServiceData.locationId" [ngModelOptions]="{ updateOn: 'blur' }" 
                                name="locationId" id="locationId" #locationId="ngModel" required  
                                class="form-control form-base form-modal minimal">
                                <option [ngValue]="location.LOCATION_ID" *ngFor="let location of locations">{{location.LOCATION_NAME }}</option>
                                <!-- <option>Select Category</option>
                                <option>Homam</option>
                                <option>Pooja</option> -->
                            </select>
                            <div *ngIf="locationId.errors && (locationId.touched || f6.submitted)">
                                <div class="error_message" *ngIf="locationId.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please select the location </i>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- <div class="form-row">
                    <div class="form-col">
                        <label class="form-label"> {{ 'SERVICES.SPECIFICATION' | translate  }}<sup
                     class="text-danger mandatory-key">*</sup></label>
                        <div class="form-group">
                            <textarea [(ngModel)]="addServiceData.specification" name="specification" class="form-control form-width-half textarea" type="text" placeholder=""></textarea>
                        </div>
                    </div>
                </div> -->
            


<!--onAddService-->
            <div class="form-row d-flex flex-wrap form-action">
                <div class="form-col">
                    <a (click)="onCancelService()" class="btn btn-secondary btn-medium">{{'BUTTONS.BTN_CANCEL' | translate }}</a>                    
                    <button type="submit" *ngIf="isAddMode" [disabled]="!f6.form.valid" 
                    class="btn btn-brand btn-medium">{{'BUTTONS.BTN_ADD_SERVICE' | translate }}</button>
                    <button type="button" *ngIf="!isAddMode" [disabled]="!f6.form.valid"
                    (click)="f6.form.valid && onEditImage(f6, imageId)" class="btn btn-brand btn-medium">{{'BUTTONS.BTN_EDIT_SERVICE' | translate }}</button>
                </div>
            </div>
        </div>
        </form>
    
</section>