<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
   <section class="page-head">
      <h3 class="page-title">Reports</h3>
   </section>

<div class="row">
    <div class="col col-md-8 col-sm-12">
        <div class="card mt-3">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="d-flex justify-content-between align-items">
                        <h4 class="widget-header mb-0">Revenue by Vathiyar</h4>
                        <a class="btn-link">View more</a>
                    </div>
                        
                        
                </div>
            </div>
        </div>
    </div>
    <div class="col col-md-4 col-sm-12">
        <div class="card mt-3">
            <div class="card-body">
                <div class="xp-widget-box">
                    <div class="d-flex justify-content-between align-items">
                        <h4 class="widget-header mb-0">Total Sales</h4>
                        <a class="btn-link">View more</a>
                    </div>
                        
                        
                </div>
            </div>
        </div>
    </div>
   
   
</div>

<div class="card mt-3">
    <div class="card-body">
        <div class="xp-widget-box">
            <div class="d-flex justify-content-between align-items">
                <h4 class="widget-header mb-0">Invoice Summary</h4>
            </div>

        </div>
    
        <table class="table">
            <thead>
            <tr>
                <th>Invoice #</th>
                <th>Summary</th>
                <th>Date</th>
                <th>Total Amount</th>
                <th>Transaction Type</th>
                <th>Invoice Generated</th>
                <th colspan="2"></th>
            </tr>
            </thead>
            <tbody>
                <tr>
                <td data-label="Invoice #">#789021</td>
                <td data-label="Summary">Ganapathy Homam</td>
                <td data-label="Date">Nov 5, 2020</td>
                <td data-label="Total Amount">Rs. 7,400</td>
                <td data-label="Transaction Type">Cash</td>
                <td data-label="Invoice Generated"><i class="icon-ic_notes ic-align-middle"></i><a class="btn-link"> Download</a></td>
                </tr> 
                <tr>
                    <td data-label="Invoice #">#789021</td>
                    <td data-label="Summary">Ganapathy Homam</td>
                    <td data-label="Date">Nov 5, 2020</td>
                    <td data-label="Total Amount">Rs. 7,400</td>
                    <td data-label="Transaction Type">Cash</td>
                    <td data-label="Invoice Generated"><i class="icon-ic_notes ic-align-middle"></i><a class="btn-link"> Download</a></td>
                    </tr> 
                    <tr>
                        <td data-label="Invoice #">#789021</td>
                        <td data-label="Summary">Ganapathy Homam</td>
                        <td data-label="Date">Nov 5, 2020</td>
                        <td data-label="Total Amount">Rs. 7,400</td>
                        <td data-label="Transaction Type">Cash</td>
                        <td data-label="Invoice Generated"><i class="icon-ic_notes ic-align-middle"></i><a class="btn-link"> Download</a></td>
                        </tr> 
            
            </tbody>
        </table>
</div>
 </div>
 

   </section>
