<aside class="sidebar">
    <!-- <div class="d-flex brand-wrap">
       <div class="brand-logo">
           <img src="assets/images/vathiyar-logo.jpg" alt="Vathiyar">
       </div>
       
       </div> -->
    <!-- <span class="toggle"><i class="icon-collapsed"><span class="path1"></span><span class="path2"></span></i><i class="icon-expand"><span class="path1"></span><span class="path2"></span></i></span> -->
    <ul class="menu-list">
       <li class="menu-item"><a style="opacity:0.5;"><i class="icon-ic_dashboard mr-1"></i> <span class="menu-name">Dashboard</span></a></li>
       <li class="menu-item">
         <a>
            <i class="mr-1 align-bottom">
               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                  <path fill="#5A5A5A" d="M12.75 9.188a.563.563 0 01.563-.563h2.624a.562.562 0 110 1.125h-2.624a.562.562 0 01-.563-.563zM13.313 11.25a.562.562 0 100 1.125h2.624a.562.562 0 100-1.125h-2.624zM11.25 9A1.125 1.125 0 119 9a1.125 1.125 0 012.25 0zM5.25 10.875a.75.75 0 00-.75.75v.75s0 1.5 2.063 1.5c2.062 0 2.062-1.5 2.062-1.5v-.75a.75.75 0 00-.75-.75H5.25zM7.875 8.813a1.313 1.313 0 11-2.625 0 1.313 1.313 0 012.625 0zM10.125 13.125h-.911a2.279 2.279 0 00.16-.699v-.801c0-.273-.073-.53-.2-.75h1.7a.75.75 0 01.75.75 1.5 1.5 0 01-1.5 1.5z"/>
                  <path fill="#5A5A5A" d="M1.503 5.813A2.813 2.813 0 014.315 3h12.372A2.813 2.813 0 0119.5 5.813v9.375A2.813 2.813 0 0116.687 18H4.317a2.812 2.812 0 01-2.813-2.813V5.813h-.001zm2.812-1.688a1.687 1.687 0 00-1.687 1.688v9.375a1.687 1.687 0 001.687 1.687h12.372a1.688 1.688 0 001.688-1.688V5.813a1.688 1.688 0 00-1.688-1.688H4.315z"/>
                </svg>
                
                 
            </i>
            <span class="menu-name">Master</span>
            <ul class="submenu">
               <li class="submenu-item" routerLink="/service-category">Service Category</li>
               <li class="submenu-item" routerLink="/package">Package</li>
               <li class="submenu-item" routerLink="/inclusion">Inclusion</li>
               <li class="submenu-item" routerLink="/location">Location</li>
               <li class="submenu-item" routerLink="/community">Community</li>
               <li class="submenu-item" routerLink="/language">Languages</li>
               
            </ul>
         </a>
      </li>
      <li class="menu-item"><a routerLink="/users" ><i class="icon-user  mr-1"></i> <span class="menu-name">Users</span></a></li>
      <li class="menu-item">
         <a routerLink="/vathiyars">
            <i class="mr-1 align-bottom">
               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                   <path fill="#5A5A5A" d="M10.762 10.693a4.304 4.304 0 001.488-3.255 4.375 4.375 0 10-8.75 0 4.305 4.305 0 001.487 3.255 7 7 0 00-4.112 6.37.875.875 0 101.75 0 5.25 5.25 0 0110.5 0 .875.875 0 101.75 0 7 7 0 00-4.113-6.37zm-2.887-.63a2.625 2.625 0 110-5.25 2.625 2.625 0 010 5.25zm8.523.28a4.374 4.374 0 00-3.273-7.28.875.875 0 100 1.75 2.625 2.625 0 012.625 2.625 2.625 2.625 0 01-1.313 2.266.876.876 0 00-.043 1.487l.341.228.114.061a6.125 6.125 0 013.5 5.582.875.875 0 101.75 0 7.875 7.875 0 00-3.701-6.72z"/>
                 </svg>
                 
            </i>
            <span class="menu-name">Vathiyars</span>
         </a>
      </li>
       <li class="menu-item"><a routerLink="/orders"><i class="icon-ic_notes mr-1"></i> <span class="menu-name">Services</span></a></li>
      
      
       <li class="menu-item">
          <a routerLink="/service-card">
             <i class="mr-1 align-bottom">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                    <path fill="#5A5A5A" d="M12.75 9.188a.563.563 0 01.563-.563h2.624a.562.562 0 110 1.125h-2.624a.562.562 0 01-.563-.563zM13.313 11.25a.562.562 0 100 1.125h2.624a.562.562 0 100-1.125h-2.624zM11.25 9A1.125 1.125 0 119 9a1.125 1.125 0 012.25 0zM5.25 10.875a.75.75 0 00-.75.75v.75s0 1.5 2.063 1.5c2.062 0 2.062-1.5 2.062-1.5v-.75a.75.75 0 00-.75-.75H5.25zM7.875 8.813a1.313 1.313 0 11-2.625 0 1.313 1.313 0 012.625 0zM10.125 13.125h-.911a2.279 2.279 0 00.16-.699v-.801c0-.273-.073-.53-.2-.75h1.7a.75.75 0 01.75.75 1.5 1.5 0 01-1.5 1.5z"/>
                    <path fill="#5A5A5A" d="M1.503 5.813A2.813 2.813 0 014.315 3h12.372A2.813 2.813 0 0119.5 5.813v9.375A2.813 2.813 0 0116.687 18H4.317a2.812 2.812 0 01-2.813-2.813V5.813h-.001zm2.812-1.688a1.687 1.687 0 00-1.687 1.688v9.375a1.687 1.687 0 001.687 1.687h12.372a1.688 1.688 0 001.688-1.688V5.813a1.688 1.688 0 00-1.688-1.688H4.315z"/>
                  </svg>
                  
             </i>
             <span class="menu-name">Service Card</span>
          </a>
       </li>
       <!-- <li class="menu-item">
         <a routerLink="/service-category">
            <i class="mr-1 align-bottom">
               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                   <path fill="#5A5A5A" d="M12.75 9.188a.563.563 0 01.563-.563h2.624a.562.562 0 110 1.125h-2.624a.562.562 0 01-.563-.563zM13.313 11.25a.562.562 0 100 1.125h2.624a.562.562 0 100-1.125h-2.624zM11.25 9A1.125 1.125 0 119 9a1.125 1.125 0 012.25 0zM5.25 10.875a.75.75 0 00-.75.75v.75s0 1.5 2.063 1.5c2.062 0 2.062-1.5 2.062-1.5v-.75a.75.75 0 00-.75-.75H5.25zM7.875 8.813a1.313 1.313 0 11-2.625 0 1.313 1.313 0 012.625 0zM10.125 13.125h-.911a2.279 2.279 0 00.16-.699v-.801c0-.273-.073-.53-.2-.75h1.7a.75.75 0 01.75.75 1.5 1.5 0 01-1.5 1.5z"/>
                   <path fill="#5A5A5A" d="M1.503 5.813A2.813 2.813 0 014.315 3h12.372A2.813 2.813 0 0119.5 5.813v9.375A2.813 2.813 0 0116.687 18H4.317a2.812 2.812 0 01-2.813-2.813V5.813h-.001zm2.812-1.688a1.687 1.687 0 00-1.687 1.688v9.375a1.687 1.687 0 001.687 1.687h12.372a1.688 1.688 0 001.688-1.688V5.813a1.688 1.688 0 00-1.688-1.688H4.315z"/>
                 </svg>
                 
            </i>
            <span class="menu-name">Service Category</span>
         </a>
      </li>
      <li class="menu-item">
         <a routerLink="/package">
            <i class="mr-1 align-bottom">
               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                   <path fill="#5A5A5A" d="M12.75 9.188a.563.563 0 01.563-.563h2.624a.562.562 0 110 1.125h-2.624a.562.562 0 01-.563-.563zM13.313 11.25a.562.562 0 100 1.125h2.624a.562.562 0 100-1.125h-2.624zM11.25 9A1.125 1.125 0 119 9a1.125 1.125 0 012.25 0zM5.25 10.875a.75.75 0 00-.75.75v.75s0 1.5 2.063 1.5c2.062 0 2.062-1.5 2.062-1.5v-.75a.75.75 0 00-.75-.75H5.25zM7.875 8.813a1.313 1.313 0 11-2.625 0 1.313 1.313 0 012.625 0zM10.125 13.125h-.911a2.279 2.279 0 00.16-.699v-.801c0-.273-.073-.53-.2-.75h1.7a.75.75 0 01.75.75 1.5 1.5 0 01-1.5 1.5z"/>
                   <path fill="#5A5A5A" d="M1.503 5.813A2.813 2.813 0 014.315 3h12.372A2.813 2.813 0 0119.5 5.813v9.375A2.813 2.813 0 0116.687 18H4.317a2.812 2.812 0 01-2.813-2.813V5.813h-.001zm2.812-1.688a1.687 1.687 0 00-1.687 1.688v9.375a1.687 1.687 0 001.687 1.687h12.372a1.688 1.688 0 001.688-1.688V5.813a1.688 1.688 0 00-1.688-1.688H4.315z"/>
                 </svg>
                 
            </i>
            <span class="menu-name">Package</span>
         </a>
      </li>
      <li class="menu-item">
         <a routerLink="/community">
            <i class="mr-1 align-bottom">
               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                   <path fill="#5A5A5A" d="M12.75 9.188a.563.563 0 01.563-.563h2.624a.562.562 0 110 1.125h-2.624a.562.562 0 01-.563-.563zM13.313 11.25a.562.562 0 100 1.125h2.624a.562.562 0 100-1.125h-2.624zM11.25 9A1.125 1.125 0 119 9a1.125 1.125 0 012.25 0zM5.25 10.875a.75.75 0 00-.75.75v.75s0 1.5 2.063 1.5c2.062 0 2.062-1.5 2.062-1.5v-.75a.75.75 0 00-.75-.75H5.25zM7.875 8.813a1.313 1.313 0 11-2.625 0 1.313 1.313 0 012.625 0zM10.125 13.125h-.911a2.279 2.279 0 00.16-.699v-.801c0-.273-.073-.53-.2-.75h1.7a.75.75 0 01.75.75 1.5 1.5 0 01-1.5 1.5z"/>
                   <path fill="#5A5A5A" d="M1.503 5.813A2.813 2.813 0 014.315 3h12.372A2.813 2.813 0 0119.5 5.813v9.375A2.813 2.813 0 0116.687 18H4.317a2.812 2.812 0 01-2.813-2.813V5.813h-.001zm2.812-1.688a1.687 1.687 0 00-1.687 1.688v9.375a1.687 1.687 0 001.687 1.687h12.372a1.688 1.688 0 001.688-1.688V5.813a1.688 1.688 0 00-1.688-1.688H4.315z"/>
                 </svg>
                 
            </i>
            <span class="menu-name">Community</span>
         </a>
      </li>
      <li class="menu-item">
         <a routerLink="/language">
            <i class="mr-1 align-bottom">
               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                   <path fill="#5A5A5A" d="M12.75 9.188a.563.563 0 01.563-.563h2.624a.562.562 0 110 1.125h-2.624a.562.562 0 01-.563-.563zM13.313 11.25a.562.562 0 100 1.125h2.624a.562.562 0 100-1.125h-2.624zM11.25 9A1.125 1.125 0 119 9a1.125 1.125 0 012.25 0zM5.25 10.875a.75.75 0 00-.75.75v.75s0 1.5 2.063 1.5c2.062 0 2.062-1.5 2.062-1.5v-.75a.75.75 0 00-.75-.75H5.25zM7.875 8.813a1.313 1.313 0 11-2.625 0 1.313 1.313 0 012.625 0zM10.125 13.125h-.911a2.279 2.279 0 00.16-.699v-.801c0-.273-.073-.53-.2-.75h1.7a.75.75 0 01.75.75 1.5 1.5 0 01-1.5 1.5z"/>
                   <path fill="#5A5A5A" d="M1.503 5.813A2.813 2.813 0 014.315 3h12.372A2.813 2.813 0 0119.5 5.813v9.375A2.813 2.813 0 0116.687 18H4.317a2.812 2.812 0 01-2.813-2.813V5.813h-.001zm2.812-1.688a1.687 1.687 0 00-1.687 1.688v9.375a1.687 1.687 0 001.687 1.687h12.372a1.688 1.688 0 001.688-1.688V5.813a1.688 1.688 0 00-1.688-1.688H4.315z"/>
                 </svg>
                 
            </i>
            <span class="menu-name">Language</span>
         </a>
      </li>
      <li class="menu-item">
         <a routerLink="/location">
            <i class="mr-1 align-bottom">
               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                   <path fill="#5A5A5A" d="M12.75 9.188a.563.563 0 01.563-.563h2.624a.562.562 0 110 1.125h-2.624a.562.562 0 01-.563-.563zM13.313 11.25a.562.562 0 100 1.125h2.624a.562.562 0 100-1.125h-2.624zM11.25 9A1.125 1.125 0 119 9a1.125 1.125 0 012.25 0zM5.25 10.875a.75.75 0 00-.75.75v.75s0 1.5 2.063 1.5c2.062 0 2.062-1.5 2.062-1.5v-.75a.75.75 0 00-.75-.75H5.25zM7.875 8.813a1.313 1.313 0 11-2.625 0 1.313 1.313 0 012.625 0zM10.125 13.125h-.911a2.279 2.279 0 00.16-.699v-.801c0-.273-.073-.53-.2-.75h1.7a.75.75 0 01.75.75 1.5 1.5 0 01-1.5 1.5z"/>
                   <path fill="#5A5A5A" d="M1.503 5.813A2.813 2.813 0 014.315 3h12.372A2.813 2.813 0 0119.5 5.813v9.375A2.813 2.813 0 0116.687 18H4.317a2.812 2.812 0 01-2.813-2.813V5.813h-.001zm2.812-1.688a1.687 1.687 0 00-1.687 1.688v9.375a1.687 1.687 0 001.687 1.687h12.372a1.688 1.688 0 001.688-1.688V5.813a1.688 1.688 0 00-1.688-1.688H4.315z"/>
                 </svg>
                 
            </i>
            <span class="menu-name">Location</span>
         </a>
      </li>
      <li class="menu-item">
         <a routerLink="/inclusion">
            <i class="mr-1 align-bottom">
               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                   <path fill="#5A5A5A" d="M12.75 9.188a.563.563 0 01.563-.563h2.624a.562.562 0 110 1.125h-2.624a.562.562 0 01-.563-.563zM13.313 11.25a.562.562 0 100 1.125h2.624a.562.562 0 100-1.125h-2.624zM11.25 9A1.125 1.125 0 119 9a1.125 1.125 0 012.25 0zM5.25 10.875a.75.75 0 00-.75.75v.75s0 1.5 2.063 1.5c2.062 0 2.062-1.5 2.062-1.5v-.75a.75.75 0 00-.75-.75H5.25zM7.875 8.813a1.313 1.313 0 11-2.625 0 1.313 1.313 0 012.625 0zM10.125 13.125h-.911a2.279 2.279 0 00.16-.699v-.801c0-.273-.073-.53-.2-.75h1.7a.75.75 0 01.75.75 1.5 1.5 0 01-1.5 1.5z"/>
                   <path fill="#5A5A5A" d="M1.503 5.813A2.813 2.813 0 014.315 3h12.372A2.813 2.813 0 0119.5 5.813v9.375A2.813 2.813 0 0116.687 18H4.317a2.812 2.812 0 01-2.813-2.813V5.813h-.001zm2.812-1.688a1.687 1.687 0 00-1.687 1.688v9.375a1.687 1.687 0 001.687 1.687h12.372a1.688 1.688 0 001.688-1.688V5.813a1.688 1.688 0 00-1.688-1.688H4.315z"/>
                 </svg>
                 
            </i>
            <span class="menu-name">Inclusions</span>
         </a>
      </li> -->
      
       <li class="menu-item"><a style="opacity:0.5;"><i class="icon-ic_book  mr-1"></i> <span class="menu-name">Reports</span></a></li>
       <li class="menu-item">
          <a routerLink="/reviews" >
             <i class="mr-1 align-bottom">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                    <path stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.69 2.304c.28-.739 1.34-.739 1.62 0l1.811 5.017c.127.333.45.554.81.554h4.452c.822 0 1.181 1.024.535 1.525l-3.168 2.85a.847.847 0 00-.282.956l1.157 4.902c.282.788-.63 1.464-1.32.98l-4.302-2.73a.875.875 0 00-1.006 0l-4.302 2.73c-.69.484-1.602-.193-1.32-.98l1.157-4.902a.847.847 0 00-.282-.956L2.083 9.4c-.648-.501-.287-1.525.533-1.525h4.451a.865.865 0 00.81-.554L9.69 2.304h0z"/>
                  </svg>
                  
             </i>
             <span class="menu-name">Reviews</span>
          </a>
       </li>
       <li class="menu-item">
          <a routerLink="/coupons">
             <i class="mr-1 align-bottom">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
                    <path fill="#5A5A5A" d="M18.047 6.357H15.02c.279-.438.443-.96.443-1.517 0-1.56-1.27-2.83-2.83-2.83-.85 0-1.614.377-2.133.972a2.827 2.827 0 00-2.133-.972c-1.56 0-2.83 1.27-2.83 2.83 0 .558.162 1.079.443 1.517H2.953a.656.656 0 00-.656.657v4.101c0 .09.074.164.164.164h.82v7.055c0 .363.294.656.656.656h13.126a.656.656 0 00.656-.656v-7.055h.82c.09 0 .164-.073.164-.164V7.014a.655.655 0 00-.656-.657zm-6.85-1.517c0-.792.644-1.436 1.436-1.436.791 0 1.435.644 1.435 1.436 0 .791-.644 1.435-1.435 1.435h-1.436V4.84zm-2.83-1.436c.792 0 1.436.644 1.436 1.436v1.435H8.367A1.437 1.437 0 016.932 4.84c0-.792.644-1.436 1.435-1.436zm-4.676 6.48V7.753h6.112v2.133H3.69zm.985 1.395h5.127v6.317H4.676v-6.317zm11.648 6.317h-5.127v-6.317h5.127v6.317zm.985-7.711h-6.112V7.752h6.112v2.133z"/>
                  </svg>
                  
             </i>
             <span class="menu-name">Coupons</span>
          </a>
       </li>
       
       <!-- <li class="menu-item"><a routerLink="/settings" ><i class="icon-settings  mr-1"></i> <span class="menu-name">Settings</span></a></li> -->
    </ul>
    <div class="profile-block">
       <div class="user-profile d-flex">
          <span class="product-logo">
             <p class="product-name">Product from</p>
             <a href="http://strawberrylabs.com/" target="_blank" class="btn-link text-center">
                <!-- <img src="/03aaabcac379bc88c79f221e520a9bab.png" alt="StrawberryLabs" class="product-logo"/> -->
                StrawberryLabs
             </a>
          </span>
       </div>
    </div>
 </aside>