<header>
     <div class="d-flex">
        <div class="brand-logo">
            <img src="assets/images/vathiyar-logo.jpg" alt="Vathiyar">
        </div>
   
    </div>
    <!-- <div class="d-flex d-none">
        <div class="hamberg-menu mt-1">
            <span class="menu-bar"></span>
            <span class="menu-bar"></span>
            <span class="menu-bar"></span>
        </div>
    </div> -->
    <div class="action-items">
        <!-- <div class="notifybar mr-2">
            <div ngbDropdown class="d-inline-block text-right">
                <a class="dropdown-toggle item-notification" ngbDropdownToggle><i class="icon-alarm icon-3x"></i><span class="circle"></span></a>
                <div ngbDropdownMenu>
                        <ul class="list-unstyled">
                            <li class="dropdown-item">
                                <div class="media-body">
                                    <h5 class="action-title">New trends for you</h5>
                                    <p><span class="timing">10 min ago</span></p>
                                </div>
                            </li>
                            <li class="dropdown-item">
                                <div class="media-body">
                                    <h5 class="action-title">John birthday today</h5>
                                    <p><span class="timing">Today, 12:00 AM</span></p>
                                </div>
                            </li>
                            <li class="dropdown-item">
                                <div class="media-body">
                                    <h5 class="action-title">This is start of your story</h5>
                                    <p><span class="timing">Yesterday, 01:25 PM</span></p>
                                </div>
                            </li>
                            <li class="dropdown-item">
                                <div class="media-body">
                                    <h5 class="action-title">Admin has 1 new like</h5>
                                    <p><span class="timing">5 Feb 2020, 03:31 PM</span></p>
                                </div>
                            </li>
                        </ul>
                    </div>
             </div>

        </div> -->
        <div class="user-block mr-2">
            <div ngbDropdown class="d-inline-block text-right">
                <div class="user-profile align-item-center d-flex dropdown-toggle" ngbDropdownToggle>
                    <span class="user-avatar mr-1"><img src="assets/images/users/default.png" alt="user-image" class="rounded-full"></span>
                    <span class="user-name">{{username}}</span>
                    <i class="icon-chevron-down icon-2x ml-1 mt-1"></i>
                </div>
                <div ngbDropdownMenu>
                    <ul class="list-unstyled">
                        <li class="dropdown-item" (click)="logoutFn()">
                            <a>Logout</a>
                        </li>
                    </ul>
                    </div>
            </div>
        </div>
</div>
</header>