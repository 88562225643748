<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
        <div class="page-head">
            <h3 *ngIf="isAddMode" class="page-title">
                <i class="icon-back mr-1"></i> {{ 'LANGUAGE.ADD_LANGUAGE' | translate }}
            </h3>
            <h3 *ngIf="!isAddMode" class="page-title">
                <i class="icon-back mr-1"></i> {{ 'LANGUAGE.EDIT_LANGUAGE' | translate }}
            </h3>
        </div>
        <form class="form-box form-block inner-block mt-2"(ngSubmit)="f2.form.valid && onAddLanguage(f2)" #f2="ngForm"
        novalidate>
            <div class="card">
                <div class="form-row">
                    <div class="form-col mr-1">
                        <label class="form-label"> {{ 'LANGUAGE.LANGUAGE_NAME' | translate }}</label>
                        <div class="form-group">
                            <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditLanguageData.languageName" id="languageName" #languageName="ngModel"
                            required name="languageName" placeholder="">
                            <div *ngIf="languageName.errors && (languageName.touched || f2.submitted)">
                                <div class="error_message" *ngIf="languageName.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter a language name</i>
                                </div>
                            </div>

                            <!-- <input [(ngModel)]="addEditLanguageData.languageName" name="languageName"  class="form-control form-modal" type="text" placeholder=""> -->
                        </div>
                    </div>

                 
                </div>
                <div class="form-row">
                    <div class="form-col mr-1">
                        <label class="form-label"> {{ 'LANGUAGE.CODE' | translate }}</label>
                        <div class="form-group">
                            <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addEditLanguageData.languageCode" id="languageCode" maxlength="3"
                            #languageCode="ngModel" required name="languageCode" placeholder="Enter the letter language code">
                            <div *ngIf="languageCode.errors && (languageCode.touched || f2.submitted)">
                                <div class="error_message" *ngIf="languageCode.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter the language code</i>
                                </div>
                            </div>
                            <!-- <input [(ngModel)]="addEditLanguageData.languageCode" name="languageCode"  class="form-control form-modal" type="text" placeholder="Enter letter language code" maxlength="3"> -->
                        </div>
                    </div>

                 
                </div>
               
               
                <div class="form-row">
                    <div class="form-col">
                        <label class="form-label">  {{ 'LANGUAGE.DESCRIPTION' | translate }}</label>
                        <div class="form-group">
                            <textarea type="text" class="form-control form-width-half textarea"
                            [ngModelOptions]="{ updateOn: 'blur' }" [(ngModel)]="addEditLanguageData.languageDesc"
                            name="languageDesc" id="languageDesc" #languageDesc="ngModel" required
                            placeholder=""></textarea>
                            <div *ngIf="languageDesc.errors && (languageDesc.touched || f2.submitted)">
                                <div class="error_message" *ngIf="languageDesc.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter language description</i>
                                </div>
                            </div>
                            <!-- <textarea [(ngModel)]="addEditLanguageData.languageDesc" name="languageDesc" class="form-control form-width-half textarea" type="text" placeholder=""></textarea> -->
                        </div>
                    </div>
                </div>
            



            <div class="form-row d-flex flex-wrap form-action">
                <div class="form-col">
                    <a (click)="onCancelLanguage()" class="btn btn-secondary btn-medium">{{ 'BUTTONS.BTN_CANCEL' | translate }}</a>
                    <button type="submit" *ngIf="isAddMode" [disabled]="!f2.form.valid" 
                        class="btn btn-brand btn-medium">{{ 'BUTTONS.BTN_ADD_LANGUAGE' | translate }}</button>
                    <button *ngIf="!isAddMode" [disabled]="!f2.form.valid" (click)="f2.form.valid && onEditLanguage(f2)" class="btn btn-brand btn-medium">{{ 'BUTTONS.BTN_EDIT_LANGUAGE' | translate }}</button>
                </div>
            </div>
        </div>
        </form>
    
</section>