<div class="modal" >
    <div class="modal-header">
      <!-- <button type="button" class="close" (click)="cancel()">&times;</button> -->
      <h3>{{ title }}</h3>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="save()">Ok</button>
    </div>
</div>

<!-- Modal -->
<!-- <div class="modal fade">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="cancel()">&times;</button>
        <h3>{{ title }}</h3>
      </div>
      <div class="modal-body">
        {{ message }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-primary"  (click)="save()">Save</button>
      </div>
    </div>
  </div>
</div> -->