<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
   <section class="page-head">
      <h3 class="page-title">{{ 'LOCATION.HD_TITLE' | translate }}</h3>
     
   </section>
   <div class="d-flex flex-wrap justify-content-between mb-2">
      <div class="d-flex search-box form-position mr-2"><i class="icon-search vertical-middle"></i><input type="text" placeholder="Search by location" class="form-control form-filter form-md form-modal round-md" name="keyword" value="" [(ngModel)]="term"/></div>
      <a class="btn btn-primary" routerLink="/addlocation">{{ 'BUTTONS.BTN_ADD_LOCATION' | translate }}</a>
   </div>
<div class="card">
   <table class="table">
      <thead>
         <tr>
            <th>{{ 'LOCATION.LOCATION_NAME' | translate }}</th>
            <th>{{ 'LOCATION.CODE' | translate }}</th>
            <th>{{ 'LOCATION.DESCRIPTION' | translate }}</th>
            <th colspan="2"></th>
         </tr>
      </thead>
      <tbody>
         <tr *ngFor="let location of locationsList | filter:term | orderBy: 'LOCATION_ID' : true | slice: (page-1) * pageSize : page * pageSize ">
            <td data-label="Location Name">{{location.LOCATION_NAME}}</td>
            <td data-label="Code">{{location.LOCATION_PINCODE}}</td>
            <td data-label="Description">{{location.LOCATION_DESC}}</td>
            <td>
               <div ngbDropdown class="d-inline-block text-right">
                  <span class="more-link" ngbDropdownToggle><span class="icon-ic_ellipsis icon-3x"></span></span>
                  <div ngbDropdownMenu>
                     <button ngbDropdownItem (click)="onEditLocation(location)"><i class="icon-edit icon-2x mr-1"></i>{{
                        'BUTTONS.BTN_EDIT' | translate }}</button>
                     <button ngbDropdownItem (click)="onClickDelete(location)"><i
                           class="icon-trash icon-2x mr-1"></i>{{ 'BUTTONS.BTN_DELETE' | translate }}</button>
                  </div>
               </div>
            </td>
         </tr>
      </tbody>
   </table>
</div>
<div class="pagination-wrap d-flex flex-wrap justify-content-between" *ngIf="locationsList.length !== 0">
   <p>{{'LABEL.LABEL_1' | translate }} {{page}} {{'LABEL.LABEL_2' | translate }} {{totNoOfPages}} </p>
   <ngb-pagination class="d-flex justify-content-end" [collectionSize]="locationsList.length" [pageSize]="pageSize"
       [(page)]="page"></ngb-pagination>
</div>
</section>