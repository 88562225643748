<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="slide-content">
    <div class="page-head">
        <h3 *ngIf="isAddMode" class="page-title">
            <i class="icon-back mr-1"></i> {{ 'USERS.ADD_USER' | translate }}
        </h3>
        <h3 *ngIf="!isAddMode" class="page-title">
            <i class="icon-back mr-1"></i> {{ 'USERS.EDIT_USER' | translate }}
        </h3>
    </div>
    <form class="form-box form-block inner-block mt-2" (ngSubmit)="f8.form.valid && onAddUser(f8)" #f8="ngForm"
    novalidate>
        <div class="card">
            <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                <div class="form-col mr-1">
                    <label class="form-label"> {{'USERS.FIRST_NAME' | translate }}</label>
                    <div class="form-group">
                        <!-- <input [(ngModel)]="addUserData.fname" (ngModelChange)="dataChanged($event)"  
                        name="fname" class="form-control form-modal" type="text"
                            placeholder="" appAlphabetValidator>
                         -->
                        <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                            [(ngModel)]="addUserData.fname" id="fname" #fname="ngModel"
                            required name="fname" placeholder="">
                        <div *ngIf="fname.errors && (fname.touched || f8.submitted)">
                            <div class="error_message" *ngIf="fname.errors?.required">
                                <span class="e_arrow"></span>
                                <i>Please enter the first name</i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-col">
                    <label class="form-label"> {{'USERS.LAST_NAME' | translate }}</label>
                    <div class="form-group">
                        <!-- <input [(ngModel)]="addUserData.lname" name="lname" class="form-control form-modal" type="text"
                            placeholder="" appAlphabetValidator> -->

                            <input type="text" class="form-control form-modal" [ngModelOptions]="{ updateOn: 'blur' }"
                                [(ngModel)]="addUserData.lname" id="lname" #lname="ngModel"
                                required name="lname" placeholder="">
                            <div *ngIf="lname.errors && (lname.touched || f8.submitted)">
                                <div class="error_message" *ngIf="lname.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please enter the last name</i>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label class="form-label"> {{'USERS.EMAIL' | translate }}</label>
                    <div class="form-group">
                        <!-- <input [(ngModel)]="addUserData.email" (ngModelChange)="dataChanged($event)" name="email" 
                        class="form-control" type="email"
                            placeholder="" appEmailValidator/> -->
                        
                        <input type="email"  class="form-control full-width"  
                            [ngModelOptions]="{ updateOn: 'blur' }" [(ngModel)]="addUserData.email" name="email"  
                            id="email" #email="ngModel" required 
                            placeholder="" 
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" >
                            <div *ngIf="email.errors && (email.touched || f8.submitted)">
                                <div class="error_message" *ngIf="email.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Email is a required field! </i>
                                </div>
                                <div class="error_message" *ngIf="email.errors?.pattern">
                                    <span class="e_arrow"></span>
                                    <i>This is not a valid Email!!!</i>
                                </div>                            
                            </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label class="form-label"> {{ 'USERS.CONTACT_NO' | translate }}</label>
                    <div class="input-group flex-nowrap">
                        <span class="input-group-text number-prepend" id="addon-wrapping">+91</span>
                       
                        <input type="text" [(ngModel)]="addUserData.contactNo" name="contactNo" 
                            class="form-control" 
                            maxLength="10" placeholder="10 digit mobile number" 
                            id="contactNo" #contactNo="ngModel" required 
                            pattern="^[0-9]{10}$"
                            aria-label="Username" aria-describedby="addon-wrapping"
                            /> 
                      </div>
                     <div *ngIf="contactNo.errors && (contactNo.touched || f8.submitted)">
                        <div class="error_message" *ngIf="contactNo.errors?.required">
                            <span class="e_arrow"></span>
                            <i>Phone number is a required field! </i>
                        </div>
                        <div class="error_message" *ngIf="contactNo.errors?.pattern">
                            <span class="e_arrow"></span>
                            <i>This is not a valid number!</i>
                        </div>                            
                    </div>
                </div>
            </div>
            <!-- <div class="form-row">
                    <div class="form-col">
                       <label class="form-label">User Image <span class="help-text justify-content-end">(JPG and PNG with max size 5 MB)</span></label>
                       <div class="dropzone dz-clickable medium">
                          <div class="dz-default dz-message"><span>Drag and drop or <label class="custom-file-upload"><input type="file"/>browse</label> </span></div>
                       </div>
                    </div>
                </div> -->

            <div class="form-row d-flex flex-wrap align-items-center justify-space-between">
                <!-- <div class="form-col mr-1">
                        <label class="form-label"> Created Date</label>
                        <div class="form-group">
                            <input [(ngModel)]="addUserData.createdDate" name="createdDate" class="form-control form-modal" type="date" placeholder="">
                        </div>
                    </div> -->

                <div class="form-col">
                    <div class="label-group d-flex flex-wrap align-items-center justify-space-between">
                        <label class="form-label d-flex"> {{'USERS.ROLE' | translate }} </label>
                    </div>
                    <div class="form-group">
                        <!-- <select class="form-control form-base form-modal minimal" 
                        [(ngModel)]="addUserData.userType"
                            name="userType">
                            <option value="">Select Role</option> 
                            <option value="Admin">{{ 'USERS.ADMIN' | translate }}</option>
                            <option value="Customer">{{ 'USERS.CUSTOMER' | translate }}</option>
                        </select> -->

                        <select [(ngModel)]="addUserData.userType" [ngModelOptions]="{ updateOn: 'blur' }" 
                                name="userType" id="userType" #userType="ngModel" required  
                                class="form-control form-base form-modal minimal">
                                <!-- <option [ngValue]="inclusion.INCLUSIONS_ID" *ngFor="let inclusion of inclusions">{{inclusion.INCLUSIONS_NAME }}</option> -->
                                <option value="Admin">{{ 'USERS.ADMIN' | translate }}</option>
                                <option value="Customer">{{ 'USERS.CUSTOMER' | translate }}</option>
                            </select>
                            <div *ngIf="userType.errors && (userType.touched || f8.submitted)">
                                <div class="error_message" *ngIf="userType.errors?.required">
                                    <span class="e_arrow"></span>
                                    <i>Please select the user role </i>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

            <div class="form-row d-flex flex-wrap form-action">
                <div class="form-col">
                    <a (click)="onCancelUser()" class="btn btn-secondary btn-medium">{{'BUTTONS.BTN_CANCEL' | translate
                        }}</a>
                    <button type="submit" *ngIf="isAddMode" [disabled]="!f8.form.valid"                        
                        class="btn btn-brand btn-medium">{{'BUTTONS.BTN_ADD_USER' | translate }}</button>
                    <button type="button" *ngIf="!isAddMode" [disabled]="!f8.form.valid" 
                        (click)="f8.form.valid && onEditUser(f8)"
                        class="btn btn-brand btn-medium">{{'BUTTONS.BTN_EDIT_USER' | translate }}</button>
                </div>
            </div>
        </div>
    </form>

</section>